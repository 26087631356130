/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import getCurrencySymbol from '@monorepo/shared/currencies/getCurrencySymbol.js'
import getCurrencyNameForCurrenciesWithRedundantSymbols from '@monorepo/shared/currencies/getCurrencyNameForCurrenciesWithRedundantSymbols.js'
import { useTranslation } from '@multilocale/react/index.js'
import colLg3 from '@stiloso/styles/bootstrap/colLg3.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'

const primaryHeading = css`
  padding-top: 72px;
  color: var(--color-primary);
  text-transform: capitalize;
  font-size: 40px;
  line-height: 50px;
  margin: 8px 0px;
`

const pricingSection = css`
  background-color: var(--color-background);
  padding: 100px 0;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 80px 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }
`

const headingTitle = css`
  margin-bottom: 50px;

  @media (max-width: 992px) {
    margin-bottom: 0px;
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`

const headingSubtitle = css`
  margin-bottom: 50px;
  padding: 0 80px;
  @media (max-width: 992px) {
    margin-bottom: 20px;
    padding: 0;
  }
`

const planBox = css`
  background: white;
  border: 1px solid #ededed;
  overflow: hidden;
  border-radius: 4px;
`

const planHeader = css`
  background-color: var(--color-primary);
  border-radius: 0% 0% 50% 50% / 0% 0% 33% 33%;
  color: white;
  display: flex;
  flex-direction: column;
  margin: 0 -33px;
  padding: 35px 20px;
`

const planPrice = css`
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  margin-inline-start: -25px;
`

const planCurrencySymbol = css`
  font-size: 24px;
  font-weight: 400;
  padding: 5px;
  vertical-align: super;
  text-transform: lowercase;
`

const planName = css`
  letter-spacing: 6px;
  text-transform: uppercase;
`

const planBillingPeriod = css`
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;
`

const planLine = css`
  line-height: 48px;
  margin-top: 20px;
`

const planFooter = css`
  padding: 30px 0;
  z-index: 9;
`

const planSubscribeButton = css`
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  padding: 12px 36px;
  &:hover,
  &:focus-visible {
    background: var(--color-green);
    color: white;
  }
`

const Plan = ({
  amount,
  billingFrequency,
  countryCode,
  currencyCode,
  name,
  locale,
  lines,
}) => {
  const { t } = useTranslation(locale)

  if (billingFrequency === 'YEARLY') {
    amount /= 12
  }

  let currencySymbol = getCurrencySymbol(currencyCode)

  let currencyName =
    getCurrencyNameForCurrenciesWithRedundantSymbols(currencyCode)

  if (currencyCode === 'USD' && countryCode === 'US') {
    currencyName = null
  }

  amount = '' + amount
  let kilo

  if (amount.endsWith('000')) {
    kilo = amount.substring(amount.length - 3, amount.length)
    amount = amount.substring(0, amount.length - 3)
  }

  return (
    <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
      <div css={planBox}>
        <div css={planHeader}>
          {amount === '0' && (
            <span
              css={[planPrice, { marginInlineStart: 0, padding: '37px 0' }]}
            >
              {t('Free')}
            </span>
          )}
          {amount !== '0' && (
            <>
              <span css={planName}>{t(name)}</span>
              <span css={planPrice}>
                <span css={planCurrencySymbol}>{currencySymbol}</span>
                {amount}
                {kilo && <span css={{ fontSize: 18 }}>{kilo}</span>}
              </span>
              <div
                css={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontSize: 14,
                  marginTop: -5,
                  marginBottom: 15,
                }}
              >
                {t(currencyName)}
              </div>
              <span css={planBillingPeriod}>{t('Per Month')}</span>
            </>
          )}
        </div>
        {lines?.map(line => (
          <div key={line} css={planLine}>
            {line}
          </div>
        ))}

        <div css={planFooter}>
          <a css={planSubscribeButton} href="/signup">
            {t(amount !== '0' ? 'Try it for free' : 'Sign up')}
          </a>
        </div>
      </div>
    </div>
  )
}

const PricingSection = ({ locale, plans }) => {
  let billingFrequencies = []
  const { t } = useTranslation(locale)
  let [loading] = useState()
  let [selectedBillingFrequency, setSelectedBillingFrequency] = useState()

  return (
    <section id="pricing" css={pricingSection}>
      <div css={container}>
        <div css={row}>
          <div css={colSm12}>
            <div css={headingTitle}>
              <h2 css={primaryHeading}>{t('__pricing_title__')}</h2>
              <p css={headingSubtitle}>{t('__pricing_subtitle__')}</p>
            </div>
          </div>
        </div>
        {loading && <div className="loader" />}
        {!loading && billingFrequencies.length > 1 && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: 16,
                boxSizing: 'border-box',
                marginTop: 29,
                borderRadius: '4px',
                border: '2px solid var(--color-primary)',
              }}
            >
              <div
                css={{
                  height: '100%',
                  padding: '12px 16px',
                  textTransform: 'uppercase',
                  cursor:
                    selectedBillingFrequency === 'YEARLY'
                      ? 'default'
                      : 'inherit',
                  color:
                    selectedBillingFrequency === 'YEARLY'
                      ? 'white'
                      : 'var(--color-primary)',
                  backgroundColor:
                    selectedBillingFrequency === 'YEARLY'
                      ? 'var(--color-primary)'
                      : 'default',
                }}
                onClick={() => setSelectedBillingFrequency('YEARLY')}
              >
                {t('Billed yearly')}
              </div>
              <div
                css={{
                  height: '100%',
                  padding: '12px 16px',
                  textTransform: 'uppercase',
                  cursor:
                    selectedBillingFrequency === 'MONTHLY'
                      ? 'default'
                      : 'inherit',
                  color:
                    selectedBillingFrequency === 'MONTHLY'
                      ? 'white'
                      : 'var(--color-primary)',
                  backgroundColor:
                    selectedBillingFrequency === 'MONTHLY'
                      ? 'var(--color-primary)'
                      : 'default',
                }}
                onClick={() => setSelectedBillingFrequency('MONTHLY')}
              >
                {t('Billed monthly')}
              </div>
            </div>
          </div>
        )}
        {!loading && (
          <div css={[row, { justifyContent: 'center' }]}>
            {plans.map(
              ({
                amount,
                billingFrequency,
                countryCode,
                currencyCode,
                name,
                lines,
              }) => (
                <Plan
                  key={name}
                  amount={amount}
                  billingFrequency={billingFrequency}
                  countryCode={countryCode}
                  currencyCode={currencyCode}
                  locale={locale}
                  lines={lines}
                  name={name}
                />
              ),
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default PricingSection

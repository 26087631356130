/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import PricingSectionStiloso from '@stiloso/sections/PricingSection.js'

const PricingSection = ({ locale }) => (
  <PricingSectionStiloso
    locale={locale}
    plans={[
      {
        amount: 0,
        billingFrequency: 'MONTHLY',
        currencyCode: 'USD',
        name: 'Free',
        lines: [],
      },
    ]}
  />
)

export default PricingSection

/* Copyright 2013 - 2024 Waiterio LLC */
import {
  darken,
  lighten,
  setLightness,
  setSaturation,
  transparentize,
} from 'polished'
import colorGreenDefault from '@stiloso/styles/green.js'
import colorRedDefault from '@stiloso/styles/red.js'

function generateTheme({
  colorPrimary = '#021a25',
  colorSecondary = '#ffffff',
  colorBackground,
  colorGreen,
  colorRed,
}) {
  let colorPrimaryLight = lighten(0.08, colorPrimary)
  let colorSecondaryLight = lighten(0.08, colorSecondary)

  if (!colorBackground) {
    colorBackground = setSaturation(0.33, colorPrimary)
    colorBackground = setLightness(0.97, colorBackground)
  }

  let colorBase = colorPrimary
  colorBase = setSaturation(0.4, colorBase)
  colorBase = setLightness(0.24, colorBase)

  colorGreen = colorGreen || colorGreenDefault
  colorRed = colorRed || colorRedDefault

  return {
    '--color-base': colorBase,
    '--color-primary': colorPrimary,
    '--color-secondary': colorSecondary,
    '--color-hover': transparentize(0.7, colorPrimaryLight),
    '--color-focus': transparentize(0.7, colorPrimaryLight),
    '--color-active': transparentize(0.7, colorPrimary),
    '--color-primary-dark': darken(0.08, colorPrimary),
    '--color-primary-darker': darken(0.16, colorPrimary),
    '--color-primary-darkest': darken(0.24, colorPrimary),
    '--color-primary-light': colorPrimaryLight,
    '--color-primary-lighter': lighten(0.16, colorPrimary),
    '--color-primary-lightest': lighten(0.24, colorPrimary),
    '--color-secondary-dark': darken(0.08, colorSecondary),
    '--color-secondary-darker': darken(0.16, colorSecondary),
    '--color-secondary-darkest': darken(0.24, colorSecondary),
    '--color-secondary-light': colorSecondaryLight,
    '--color-secondary-lighter': lighten(0.16, colorSecondaryLight),
    '--color-secondary-lightest': lighten(0.24, colorSecondaryLight),
    '--color-green': colorGreen,
    '--color-green-dark': darken(0.08, colorGreen),
    '--color-green-darker': darken(0.16, colorGreen),
    '--color-green-darkest': darken(0.24, colorGreen),
    '--color-green-light': lighten(0.08, colorGreen),
    '--color-green-lighter': lighten(0.16, colorGreen),
    '--color-green-lightest': lighten(0.24, colorGreen),
    '--color-red': colorRed,
    '--color-red-dark': darken(0.08, colorRed),
    '--color-red-darker': darken(0.16, colorRed),
    '--color-red-darkest': darken(0.24, colorRed),
    '--color-red-light': lighten(0.08, colorRed),
    '--color-red-lighter': lighten(0.16, colorRed),
    '--color-red-lightest': lighten(0.24, colorRed),
    '--color-background': colorBackground,
  }
}

export default generateTheme

/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import DownloadsSection from '../components/DownloadsSection.js'
import FooterComponent from '../components/FooterComponent.js'
import NavigationComponent from '../components/NavigationComponent.js'
import languages from '../languages.js'

export const paths = languages.map(locale => ({
  path: `/${locale}/downloads/`,
  props: {
    locale,
  },
}))

const DownloadsPageWrapper = ({ locale }) => (
  <>
    <NavigationComponent locale={locale} />
    <ErrorBoundary>
      <DownloadsSection locale={locale} />
    </ErrorBoundary>
    <FooterComponent locale={locale} />
  </>
)

export default DownloadsPageWrapper

/* Copyright 2013 - 2024 Waiterio LLC */
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

let IS_SCREENSHOTS = false

if (IS_BROWSER && window.IS_SCREENSHOTS === '{{{IS_SCREENSHOTS}}}') {
  delete window.IS_SCREENSHOTS
}

if (IS_NODE && process.env.IS_SCREENSHOTS === '{{{IS_SCREENSHOTS}}}') {
  delete process.env.IS_SCREENSHOTS
}

if (IS_BROWSER && window.IS_SCREENSHOTS) {
  IS_SCREENSHOTS = window.IS_SCREENSHOTS
} else if (
  IS_NODE &&
  process.env.IS_SCREENSHOTS &&
  process.env.IS_SCREENSHOTS !== '{{{IS_SCREENSHOTS}}}'
) {
  IS_SCREENSHOTS = process.env.IS_SCREENSHOTS
}

// if (IS_STAGING || IS_DEVELOPMENT) {
//   console.debug('IS_SCREENSHOTS = ' + IS_SCREENSHOTS)
// }

export default IS_SCREENSHOTS

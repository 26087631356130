/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
import { useSearchParams } from 'react-router-dom'
import setCookie from '@monorepo/shared/setCookie.js'

const UtmSourceCookie = () => {
  const [searchParams] = useSearchParams()
  const utm_source = searchParams.get('utm_source')

  if (utm_source) {
    setCookie('utm_source', utm_source)
  }
}

export default UtmSourceCookie

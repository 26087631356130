/* Copyright 2013 - 2024 Waiterio LLC */

const language2paths = {
  en: 'live-chat-for-clinic',
  es: 'chat-para-sitio-web',
  fr: 'live-chat-pour-site-web',
  it: 'live-chat-per-sito-web',
}

export default language2paths

/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import colMd12 from '@stiloso/styles/bootstrap/colMd12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import unselectable from '@stiloso/styles/unselectable.js'

const bannerSection = css`
  background: var(--color-primary);
  padding: 100px 0 0;
  position: relative;

  @media (max-width: 1199px) {
    padding: 80px 0 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0 0;
  }

  @media (max-width: 576px) {
    padding: 112px 0 0;
  }

  @media (max-width: 479px) {
    padding: 96px 0 0;
  }
`

export const bannerHeadingContainer = css`
  padding: 200px 0 150px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 200px 0;
  }
`

export const bannerForeground = css`
  position: relative;
  padding-top: 96px;
  padding-bottom: 96px;
  text-align: center;

  @media (max-width: 992px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (max-width: 767px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media (max-width: 479px) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
`

export const bannerTitle = css`
  color: white;
  font-size: 56px;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;

  @media (max-width: 992px) {
    font-size: 48px;
  }

  @media (max-width: 767px) {
    font-size: 40px;
  }

  @media (max-width: 479px) {
    font-size: 32px;
  }
`

export const bannerSubtitle = css`
  color: white;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin: 24px;
  padding: 16px 48px;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 24px;
    padding: 0;
  }

  @media (max-width: 767px) {
    font-size: 18px;
  }
`

export const button = css`
  ${unselectable}
  display: block;
  box-sizing: border-box;
  background-color: var(--color-secondary);
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  margin: 0;
  padding: 0px 16px;
  text-align: center;
  white-space: nowrap;
  line-height: 48px;
  min-width: 240px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: var(--color-secondary-dark);
    }
  }

  &:not(:disabled):focus-visible {
    background-color: var(--color-secondary-dark);
  }

  &:not(:disabled):active {
    background-color: var(--color-secondary-darker);
  }

  @media (max-width: 479px) {
    font-size: 14px;
  }
`

const BannerSection = ({ title, subtitle, buttonLabel, buttonOnClick }) => (
  <section css={bannerSection}>
    <div css={container}>
      <div css={bannerForeground}>
        <div css={row}>
          <div
            css={[
              colMd12,
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
            ]}
          >
            <h1 css={bannerTitle}>{title}</h1>
            <h2 css={bannerSubtitle}>{subtitle}</h2>
            <button type="button" onClick={buttonOnClick} css={button}>
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default BannerSection

/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import domainProduction from '@veterical/shared/domainProduction.js'
import faviconUrl from '@veterical/shared/faviconUrl.js'
import colLg8 from '@stiloso/styles/bootstrap/colLg8.js'
import colLg10 from '@stiloso/styles/bootstrap/colLg10.js'
import colSm10 from '@stiloso/styles/bootstrap/colSm10.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import { useTranslation } from '@multilocale/react/index.js'
import FooterComponent from '../components/FooterComponent.js'
import NavigationComponent from '../components/NavigationComponent.js'
import languages from '../languages.js'

const style = `

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
  }

  h1 { font-size: 52px; line-height: 58px; }
  h2 { font-size: 40px; line-height: 46px; }
  h3 { font-size: 36px; line-height: 44px; }
  h4 { font-size: 24px; line-height: 24px; }
  h5 { font-size: 20px; line-height: 30px; }
  h6 { font-size: 18px; line-height: 28px; }

  p {
    margin-top: 0;
      margin-bottom: 1rem;
    font-size: 15px;
  }

  ul {
      margin: 0;
      padding: 0;
  }

  li {
      list-style: none;
  }
`

const headingTitle = css`
  margin-bottom: 80px;
  margin-top: 90px;
  text-align: center;
`

const title = css`
  font-size: 52px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 25px;
  margin-top: 0px;
  text-align: center;
`

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = t('Veterical')
  const description = t('Privacy Policy')

  return (
    <>
      <title>{`${description} | ${title}`}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="seat" />
      <meta
        property="og:url"
        content={`https://www.${domainProduction}/${locale}/privacy-policy/`}
      />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
      <link
        rel="canonical"
        href={`https://www.${domainProduction}/${locale}/privacy-policy/`}
      />
      <link
        rel="alternate"
        href={`https://www.${domainProduction}/en/privacy-policy/`}
        hreflang="x-default"
      />
      {languages.map(language => (
        <link
          key={language}
          rel="alternate"
          href={`https://www.${domainProduction}/${language}/privacy-policy/`}
          hreflang={language}
        />
      ))}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            '@id': `https://www.${domainProduction}/${locale}/`,
            url: `https://www.${domainProduction}/${locale}/`,
            inLanguage: locale,
            isPartOf: {
              '@id': `https://www.${domainProduction}/${locale}/#clinic`,
            },
            author: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            publisher: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            translationOfWork: {
              '@id': `https://www.${domainProduction}/en/#clinic`,
            },
            '@graph': [
              {
                '@type': 'WebSite',
                '@id': `https://www.${domainProduction}/${locale}/#clinic`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/${locale}/#clinic`,
                inLanguage: locale,
                author: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
                publisher: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
              },
              {
                '@type': 'Corporation',
                '@id': `https://www.${domainProduction}/#corporation`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/#corporation`,
                sameAs: [
                  `https://www.facebook.com/${domainProduction}`,
                  'https://www.linkedin.com/company/multilocale',
                ],
              },
            ],
          }),
        }}
      />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/privacy-policy/`,
  props: {
    locale,
  },
}))

const PricingPage = ({ locale }) => (
  <>
    <NavigationComponent locale={locale} />
    <section>
      <style>{style}</style>
      <div css={[container, { padding: '100px 0' }]}>
        <div css={[row, { justifyContent: 'center' }]}>
          <div css={[colSm10, colLg8]}>
            <div css={headingTitle}>
              <h1 css={title}>PRIVACY POLICY</h1>
              <p css={{ marginBottom: 50 }}>
                This privacy policy has been compiled to better serve those who
                are concerned with how their &apos;Personally Identifiable
                Information&apos; (PII) is being used online. PII, as described
                in US privacy law and information security, is information that
                can be used on its own or with other information to identify,
                contact, or locate a single person, or to identify an individual
                in context. Please read our privacy policy carefully to get a
                clear understanding of how we collect, use, protect or otherwise
                handle your Personally Identifiable Information in accordance
                with our clinic.
              </p>
            </div>
          </div>
        </div>
        <div css={[row, { justifyContent: 'center' }]}>
          <div css={[colSm12, colLg10]}>
            <h4 css={{ fontWeight: 700, marginBottom: 10 }}>
              What personal information do we collect from the people that visit
              our blog, clinic or app?
            </h4>
            <p>
              When ordering or registering on our site, as appropriate, you may
              be asked to enter your name, email address, mailing address, phone
              number, payment card information VAT or other details to help you
              with your experience.
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              When do we collect information?
            </h4>
            <p>
              We collect information from you when you register on our site,
              place an order, subscribe to a newsletter, respond to a survey,
              fill out a form, Use Live Chat, Open a Support Ticket or enter
              information on our site.
            </p>
            <p>Provide us with feedback on our products or services</p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              How do we use your information?
            </h4>
            <p>
              We may use the information we collect from you when you register,
              make a purchase, sign up for our newsletter, respond to a survey
              or marketing communication, surf the clinic, or use certain other
              site features in the following ways:
            </p>
            <ul>
              <li>
                • To personalize your experience and to allow us to deliver the
                type of content and product offerings in which you are most
                interested.
              </li>
              <li>• To improve our clinic in order to better serve you.</li>
              <li>
                • To allow us to better service you in responding to your
                customer service requests.
              </li>
              <li>
                • To administer a contest, promotion, survey or other site
                feature.
              </li>
              <li>• To quickly process your transactions.</li>
              <li>• To ask for ratings and reviews of services or products.</li>
              <li>
                • To follow up with them after correspondence (live chat, email
                or phone inquiries).
              </li>
            </ul>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              How do we protect your information?
            </h4>
            <p>
              We do not use vulnerability scanning and/or scanning to PCI
              standards. An external PCI compliant payment gateway handles all
              CC transactions. We do not use Malware Scanning.
            </p>
            <p>
              Your personal information is contained behind secured networks and
              is only accessible by a limited number of persons who have special
              access rights to such systems, and are required to keep the
              information confidential. In addition, all sensitive/credit
              information you supply is encrypted via Secure Socket Layer (SSL)
              technology.
            </p>
            <p>
              We implement a variety of security measures when a user places an
              order enters, submits, or accesses their information to maintain
              the safety of your personal information.
            </p>
            <p>
              All transactions are processed through a gateway provider and are
              not stored or processed on our servers.
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Do we use &apos;cookies&apos;?
            </h4>
            <p>
              Yes. Cookies are small files that a site or its service provider
              transfers to your computer&apos;s hard drive through your Web
              browser (if you allow) that enables the site&apos;s or service
              provider&apos;s systems to recognize your browser and capture and
              remember certain information. For instance, we use cookies to help
              us remember and process the items in your shopping cart. They are
              also used to help us understand your preferences based on previous
              or current site activity, which enables us to provide you with
              improved services. We also use cookies to help us compile
              aggregate data about site traffic and site interaction so that we
              can offer better site experiences and tools in the future.
            </p>
            <h5 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              We use cookies to:
            </h5>
            <ul>
              <li>
                • Help remember and process the items in the shopping cart.
              </li>
              <li>
                • Understand and save user&apos;s preferences for future visits.
              </li>
              <li>• Keep track of advertisements.</li>
              <li>
                • Compile aggregate data about site traffic and site
                interactions in order to offer better site experiences and tools
                in the future. We may also use trusted third-party services that
                track this information on our behalf.
              </li>
            </ul>{' '}
            <br />
            <p>
              You can choose to have your computer warn you each time a cookie
              is being sent, or you can choose to turn off all cookies. You do
              this through your browser settings. Since browser is a little
              different, look at your browser&apos;s Help Menu to learn the
              correct way to modify your cookies.
            </p>
            <p>
              If you turn cookies off, Some of the features that make your site
              experience more efficient may not function properly.It won&apos;t
              affect the user&apos;s experience that make your site experience
              more efficient and may not function properly.
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Third-party disclosure
            </h4>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties
              your Personally Identifiable Information unless we provide users
              with advance notice. This does not include clinic hosting partners
              and other parties who assist us in operating our clinic,
              conducting our business, or serving our users, so long as those
              parties agree to keep this information confidential. We may also
              release information when it&apos;s release is appropriate to
              comply with the law, enforce our site policies, or protect ours or
              others&apos; rights, property or safety.
            </p>
            <p>
              However, non-personally identifiable visitor information may be
              provided to other parties for marketing, advertising, or other
              uses.
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Third-party links
            </h4>
            <p>
              Occasionally, at our discretion, we may include or offer
              third-party products or services on our clinic. These third-party
              sites have separate and independent privacy policies. We therefore
              have no responsibility or liability for the content and activities
              of these linked sites. Nonetheless, we seek to protect the
              integrity of our site and welcome any feedback about these sites.
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Google
            </h4>
            <p>
              Google&apos;s advertising requirements can be summed up by
              Google&apos;s Advertising Principles. They are put in place to
              provide a positive experience for users.
              https://support.google.com/adwordspolicy/answer/1316548?hl=en
            </p>
            <p>We use Google AdSense Advertising on our clinic.</p>
            <p>
              Google, as a third-party vendor, uses cookies to serve ads on our
              site. Google&apos;s use of the DART cookie enables it to serve ads
              to our users based on previous visits to our site and other sites
              on the Internet. Users may opt-out of the use of the DART cookie
              by visiting the Google Ad and Content Network privacy policy.
            </p>
            <h5 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              We have implemented the following:
            </h5>
            <ul>
              <li>• Remarketing with Google AdSense</li>
              <li>• Google Display Network Impression Reporting</li>
              <li>• Demographics and Interests Reporting</li>
            </ul>{' '}
            <br />
            <p>
              We, along with third-party vendors such as Google use first-party
              cookies (such as the Google Analytics cookies) and third-party
              cookies (such as the DoubleClick cookie) or other third-party
              identifiers together to compile data regarding user interactions
              with ad impressions and other ad service functions as they relate
              to our clinic.
            </p>
            <h5 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Opting out:
            </h5>
            <p>
              Users can set preferences for how Google advertises to you using
              the Google Ad Settings page. Alternatively, you can opt out by
              visiting the Network Advertising Initiative Opt Out page or by
              using the Google Analytics Opt Out Browser add on.
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              California Online Privacy Protection Act
            </h4>
            <p>
              CalOPPA is the first state law in the nation to require commercial
              clinics and online services to post a privacy policy. The
              law&apos;s reach stretches well beyond California to require any
              person or company in the United States (and conceivably the world)
              that operates clinics collecting Personally Identifiable
              Information from California consumers to post a conspicuous
              privacy policy on its clinic stating exactly the information being
              collected and those individuals or companies with whom it is being
              shared. - See more at:
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
            </p>
            <h5 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              According to CalOPPA, we agree to the following:
            </h5>
            <ul>
              <li>• Users can visit our site anonymously.</li>
              <li>
                • Once this privacy policy is created, we will add a link to it
                on our home page or as a minimum, on the first significant page
                after entering our clinic.
              </li>
              <li>
                • Our Privacy Policy link includes the word &apos;Privacy&apos;
                and can easily be found on the page specified above.
              </li>
            </ul>{' '}
            <br />
            <p>You will be notified of any Privacy Policy changes:</p>
            <ul>
              <li>• On our Privacy Policy Page</li>
            </ul>{' '}
            <br />
            <p>Can change your personal information:</p>
            <ul>
              <li>• By logging in to your account</li>
            </ul>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              How does our site handle Do Not Track signals?
            </h4>
            <p>
              We honor Do Not Track signals and Do Not Track, plant cookies, or
              use advertising when a Do Not Track (DNT) browser mechanism is in
              place.
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Does our site allow third-party behavioral tracking?
            </h4>
            <p>
              It&apos;s also important to note that we allow third-party
              behavioral tracking
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              COPPA (Children Online Privacy Protection Act)
            </h4>
            <p>
              When it comes to the collection of personal information from
              children under the age of 13 years old, the Children&apos;s Online
              Privacy Protection Act (COPPA) puts parents in control. The
              Federal Trade Commission, United States&apos; consumer protection
              agency, enforces the COPPA Rule, which spells out what operators
              of clinics and online services must do to protect children&apos;s
              privacy and safety online.
            </p>
            <p>
              We do not specifically market to children under the age of 13
              years old.
            </p>
            <p>
              Do we let third-parties, including ad networks or plug-ins collect
              PII from children under 13?
            </p>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Fair Information Practices
            </h4>
            <p>
              The Fair Information Practices Principles form the backbone of
              privacy law in the United States and the concepts they include
              have played a significant role in the development of data
              protection laws around the globe. Understanding the Fair
              Information Practice Principles and how they should be implemented
              is critical to comply with the various privacy laws that protect
              personal information.
            </p>
            <h5 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              In order to be in line with Fair Information Practices we will
              take the following responsive action, should a data breach occur:
            </h5>
            <p>We will notify you via email</p>
            <ul>
              <li>• Within 7 business days</li>
            </ul>{' '}
            <br />
            <p>
              We also agree to the Individual Redress Principle which requires
              that individuals have the right to legally pursue enforceable
              rights against data collectors and processors who fail to adhere
              to the law. This principle requires not only that individuals have
              enforceable rights against data users, but also that individuals
              have recourse to courts or government agencies to investigate
              and/or prosecute non-compliance by data processors.
            </p>
          </div>
        </div>
      </div>
    </section>
    <FooterComponent locale={locale} />
  </>
)

export default PricingPage

/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
import { useSearchParams } from 'react-router-dom'
import setCookie from '@monorepo/shared/setCookie.js'

const UtmDeviceCookie = () => {
  const [searchParams] = useSearchParams()
  const utm_device = searchParams.get('utm_device')

  if (utm_device) {
    setCookie('utm_device', utm_device)
  }
}

export default UtmDeviceCookie

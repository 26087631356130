/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import colLg4 from '@stiloso/styles/bootstrap/colLg4.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colLg6 from '@stiloso/styles/bootstrap/colLg6.js'
import colSm10 from '@stiloso/styles/bootstrap/colSm10.js'

const section = css`
  padding: 80px 0px;
  background-color: white;

  @media (max-width: 992px) {
    padding: 64px 0px;
  }

  @media (max-width: 768px) {
    padding: 48px 0px;
  }
`

const sectionHeading = css`
  font-size: 36px;
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
  color: white;

  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 32px;
  }
`

const subsectionItem = css`
  ${colSm10}
  ${colMd6}
  ${colLg4}
  
  margin: 8px 0px;
`

const factContent = css`
  line-height: 24px;
  color: white;
  font-weight: 700;
  font-size: 16px;
`

const FactsSection = ({ title, facts }) => (
  <section
    id="interesting-facts-section"
    css={[section, { backgroundColor: 'var(--color-primary)' }]}
  >
    <div css={container}>
      <div css={[row, { justifyContent: 'center' }]}>
        <div css={[colMd6, colLg6]}>
          <h2 css={sectionHeading}>{title}</h2>
        </div>
      </div>
      <div
        css={[
          row,
          { justifyContent: 'center', textAlign: 'center', marginTop: 48 },
        ]}
      >
        {facts.map(({ content, Icon }, index) => (
          <div key={content || index} css={subsectionItem}>
            <Icon
              style={{
                width: 64,
                height: 64,
                fill: 'var(--color-secondary)',
              }}
            />
            <p css={factContent}>“{content}”</p>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default FactsSection

/* Copyright 2013 - 2024 Waiterio LLC */

const language2paths = {
  en: 'customer-support-software',
  es: 'software-de-atencion-al-cliente',
  fr: 'logiciel-de-service-client',
  it: 'software-di-servizio-clienti',
}

export default language2paths

/* Copyright 2013 - 2024 Waiterio LLC */
import getCookie from '@monorepo/shared/getCookie.js'
import setCookie from '@monorepo/shared/setCookie.js'

const ReferrerCookie = () => {
  if (typeof window !== 'undefined') {
    let referrerCookie = getCookie('referrer')

    if (!referrerCookie) {
      let referrer = window?.document?.referrer

      if (referrer) {
        referrer = referrer
          .replace(/https?:\/\//, '')
          .replace(/http?:\/\//, '')
          .replace(/\/$/, '')
      }

      if (referrer) {
        setCookie('referrer', referrer)
      }
    }
  }
}

export default ReferrerCookie

/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase, max-len, react/jsx-closing-tag-location, react/self-closing-comp */

import React from 'react'

const IllustrationVeterinary = ({ width }) => {
  width ||= 525.122

  return (
    <svg
      version="1.1"
      id="svg2"
      width={width}
      height="666.66669"
      viewBox="0 0 666.66669 666.66669"
    >
      <defs id="defs6">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath30">
          <path d="M 0,500 H 500 V 0 H 0 Z" id="path28" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath280">
          <path d="M 0,500 H 500 V 0 H 0 Z" id="path278" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath292">
          <path
            d="M 48.5919,437.598 H 446.434 V 120.729 H 48.5919 Z"
            id="path290"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath308">
          <path d="M 0,500 H 500 V 0 H 0 Z" id="path306" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath320">
          <path d="M 0,500 H 500 V 0 H 0 Z" id="path318" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath332">
          <path
            d="m 266.743,256.917 h 42.699 v -38.739 h -42.699 z"
            id="path330"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath352">
          <path
            d="m 240.603,279.78 h 24.011 v -61.508 h -24.011 z"
            id="path350"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath372">
          <path
            d="m 330.683,240.242 h 48.928 v -27.033 h -48.928 z"
            id="path370"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath392">
          <path
            d="m 282.54,264.193 h 39.365 V 221.982 H 282.54 Z"
            id="path390"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath412">
          <path
            d="m 237.392,379.059 h 22.729 v -28.001 h -22.729 z"
            id="path410"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath432">
          <path
            d="m 243.345,351.126 h 18.36 v -22.771 h -18.36 z"
            id="path430"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath468">
          <path
            d="m 278.16,369.126 h 14.096 V 335.631 H 278.16 Z"
            id="path466"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath504">
          <path
            d="m 238.38,299.454 h 34.727 v -47.75 H 238.38 Z"
            id="path502"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath530">
          <path d="M 0,500 H 500 V 0 H 0 Z" id="path528" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath538">
          <path
            d="m 208.941,216.564 h 203.971 v -7.675 H 208.941 Z"
            id="path536"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath552">
          <path
            d="m 208.941,216.564 h 203.971 v -3.837 H 208.941 Z"
            id="path550"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath570">
          <path
            d="m 202.981,219.888 h 215.89 v -4.324 h -215.89 z"
            id="path568"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath588">
          <path
            d="m 208.941,208.98 h 10.283 V 86.8582 h -10.283 z"
            id="path586"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath602">
          <path
            d="m 208.941,208.98 h 10.283 v -16.575 h -10.283 z"
            id="path600"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath618">
          <path
            d="m 402.628,208.98 h 10.284 V 86.8582 h -10.284 z"
            id="path616"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath632">
          <path
            d="m 402.628,208.98 h 10.284 v -16.575 h -10.284 z"
            id="path630"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath648">
          <path
            d="m 271.879,208.98 h 10.284 V 86.8582 h -10.284 z"
            id="path646"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath662">
          <path d="m 271.879,208.98 h 10.284 v -24 h -10.284 z" id="path660" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath678">
          <path
            d="m 339.852,208.98 h 10.283 V 86.8582 h -10.283 z"
            id="path676"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath692">
          <path d="m 339.852,208.98 h 10.283 v -24 h -10.283 z" id="path690" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath706">
          <path d="M 0,500 H 500 V 0 H 0 Z" id="path704" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath722">
          <path d="m 169.022,317.86 h 8.61 v -11.84 h -8.61 z" id="path720" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath758">
          <path d="m 141.042,337.835 h 9.792 v -5.95 h -9.792 z" id="path756" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath778">
          <path
            d="m 121.66,272.201 h 67.835 V 146.381 H 121.66 Z"
            id="path776"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath822">
          <path
            d="m 132.16,326.05 h 17.712 V 256.272 H 132.16 Z"
            id="path820"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath838">
          <path
            d="m 154.335,327.377 h 15.112 v -94.181 h -15.112 z"
            id="path836"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath854">
          <path
            d="m 128.606,302.07 h 3.554 v -13.011 h -3.554 z"
            id="path852"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath870">
          <path
            d="m 126.384,262.561 h 14.865 v -38.139 h -14.865 z"
            id="path868"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath974">
          <path d="m 245.235,339.775 h 2.7 V 328.3 h -2.7 z" id="path972" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath994">
          <path
            d="m 133.917,346.206 h 26.559 v -16.639 h -26.559 z"
            id="path992"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1014">
          <path
            d="m 150.57,334.501 h 95.19 v -71.023 h -95.19 z"
            id="path1012"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1030">
          <path d="M 0,500 H 500 V 0 H 0 Z" id="path1028" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1042">
          <path
            d="m 351.664,194.833 h 35.249 v -14.541 h -35.249 z"
            id="path1040"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1062">
          <path
            d="M 278.224,129.8 H 460.353 V 87.6051 H 278.224 Z"
            id="path1060"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1078">
          <path
            d="m 376.356,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1076"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1094">
          <path
            d="m 368.72,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1092"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1110">
          <path
            d="m 361.084,175.782 h 8.626 v -23.585 h -8.626 z"
            id="path1108"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1126">
          <path
            d="m 353.448,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1124"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1142">
          <path
            d="m 345.812,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1140"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1158">
          <path
            d="m 338.176,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1156"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1174">
          <path
            d="m 376.356,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1172"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1190">
          <path
            d="m 368.72,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1188"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1206">
          <path
            d="m 361.084,119.83 h 8.626 V 96.2447 h -8.626 z"
            id="path1204"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1222">
          <path
            d="m 353.448,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1220"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1238">
          <path
            d="m 345.812,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1236"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1254">
          <path
            d="m 338.176,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1252"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1270">
          <path
            d="m 423.163,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1268"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1286">
          <path
            d="m 415.526,175.782 h 8.628 v -23.585 h -8.628 z"
            id="path1284"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1302">
          <path
            d="m 407.891,175.782 h 8.626 v -23.585 h -8.626 z"
            id="path1300"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1318">
          <path
            d="m 400.255,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1316"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1334">
          <path
            d="m 392.619,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1332"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1350">
          <path
            d="m 384.983,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1348"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1366">
          <path
            d="m 423.163,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1364"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1382">
          <path
            d="m 415.526,119.83 h 8.628 V 96.2447 h -8.628 z"
            id="path1380"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1398">
          <path
            d="m 439.426,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1396"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1414">
          <path
            d="m 431.79,175.782 h 8.627 v -23.585 h -8.627 z"
            id="path1412"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1430">
          <path
            d="m 439.426,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1428"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1446">
          <path
            d="m 431.79,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1444"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1462">
          <path
            d="m 407.891,119.83 h 8.626 V 96.2447 h -8.626 z"
            id="path1460"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1478">
          <path
            d="m 400.255,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1476"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1494">
          <path
            d="m 392.619,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1492"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1510">
          <path
            d="m 384.983,119.83 h 8.627 V 96.2447 h -8.627 z"
            id="path1508"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1530">
          <path
            d="m 274.039,184.422 h 70.686 V 87.6051 h -70.686 z"
            id="path1528"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1546">
          <path
            d="M 274.039,173.477 H 464.538 V 98.5502 H 274.039 Z"
            id="path1544"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1562">
          <path d="m 295.7,138.656 h 26.827 v -2.608 H 295.7 Z" id="path1560" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1578">
          <path d="m 295.7,144.803 h 26.827 v -2.607 H 295.7 Z" id="path1576" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1594">
          <path d="m 295.7,150.951 h 26.827 v -2.608 H 295.7 Z" id="path1592" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1610">
          <path d="m 295.7,157.099 h 26.827 v -2.608 H 295.7 Z" id="path1608" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1626">
          <path d="m 295.7,163.246 h 26.827 v -2.607 H 295.7 Z" id="path1624" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1642">
          <path d="m 295.7,107.532 h 26.827 v -2.608 H 295.7 Z" id="path1640" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1658">
          <path d="m 295.7,113.68 h 26.827 v -2.608 H 295.7 Z" id="path1656" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1674">
          <path d="m 295.7,119.828 h 26.827 V 117.22 H 295.7 Z" id="path1672" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1690">
          <path d="m 295.7,125.975 h 26.827 v -2.607 H 295.7 Z" id="path1688" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1706">
          <path d="m 295.7,132.123 h 26.827 v -2.608 H 295.7 Z" id="path1704" />
        </clipPath>
      </defs>
      <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,666.66667)">
        <path
          d="m 0,117.352 h 500 v 0.249 H 0 Z"
          style={{
            fill: '#ececec',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path10"
        />
        <path
          d="m 416.779,101.256 h 33.122 v 0.249 h -33.122 z"
          style={{
            fill: '#ececec',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path12"
        />
        <path
          d="m 322.527,98.542 h 8.693 v 0.25 h -8.693 z"
          style={{
            fill: '#ececec',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path14"
        />
        <path
          d="m 396.586,110.542 h 19.192 v 0.25 h -19.192 z"
          style={{
            fill: '#ececec',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path16"
        />
        <path
          d="m 52.459,108.861 h 43.193 v 0.25 H 52.459 Z"
          style={{
            fill: '#ececec',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path18"
        />
        <path
          d="m 104.556,108.861 h 6.333 v 0.25 h -6.333 z"
          style={{
            fill: '#ececec',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path20"
        />
        <path
          d="m 131.471,104.639 h 93.676 v 0.25 h -93.676 z"
          style={{
            fill: '#ececec',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path22"
        />
        <g id="g24">
          <g id="g26" clipPath="url(#clipPath30)">
            <g id="g32" transform="translate(43.915,444.7974)">
              <path
                d="m 0,0 c -3.01,0 -5.458,-2.448 -5.458,-5.458 v -271.431 c 0,-3.01 2.448,-5.458 5.458,-5.458 h 193.099 c 3.008,0 5.457,2.448 5.457,5.458 V -5.458 c 0,3.01 -2.449,5.458 -5.457,5.458 z M 193.099,-282.597 H 0 c -3.147,0 -5.708,2.56 -5.708,5.708 V -5.458 c 0,3.147 2.561,5.708 5.708,5.708 h 193.099 c 3.146,0 5.707,-2.561 5.707,-5.708 v -271.431 c 0,-3.148 -2.561,-5.708 -5.707,-5.708"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path34"
              />
            </g>
            <g id="g36" transform="translate(260.2119,444.7974)">
              <path
                d="m 0,0 c -3.009,0 -5.457,-2.448 -5.457,-5.458 v -271.431 c 0,-3.01 2.448,-5.458 5.457,-5.458 h 193.099 c 3.009,0 5.458,2.448 5.458,5.458 V -5.458 c 0,3.01 -2.449,5.458 -5.458,5.458 z M 193.099,-282.597 H 0 c -3.146,0 -5.707,2.56 -5.707,5.708 V -5.458 c 0,3.147 2.561,5.708 5.707,5.708 h 193.099 c 3.147,0 5.708,-2.561 5.708,-5.708 v -271.431 c 0,-3.148 -2.561,-5.708 -5.708,-5.708"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path38"
              />
            </g>
            <path
              d="m 55.408,120.822 h 86.417 v 70.997 H 55.408 Z"
              style={{
                fill: '#e1e1e1',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path40"
            />
            <path
              d="m 57.521,190.117 h 5.877 v -67.594 h -5.877 z m 83.074,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.876 v 67.594 h 5.876 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 11.278,67.594 h 5.877 v -67.594 h -5.877 z m -2.701,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,67.594 v -67.594 h -3.474 v -3.71 h 94.538 v 3.71 h -2.59 v 67.594 h 2.59 v 3.71 H 51.347 v -3.71 z"
              style={{
                fill: '#ececec',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path42"
            />
            <path
              d="m 55.408,196.182 h 86.417 V 267.18 H 55.408 Z"
              style={{
                fill: '#e1e1e1',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path44"
            />
            <path
              d="m 57.521,265.478 h 5.877 v -67.594 h -5.877 z m 83.074,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.876 v 67.594 h 5.876 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 11.278,67.594 h 5.877 v -67.594 h -5.877 z m -2.701,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,67.594 v -67.594 h -3.474 v -3.71 h 94.538 v 3.71 h -2.59 v 67.594 h 2.59 v 3.709 H 51.347 v -3.709 z"
              style={{
                fill: '#f0f0f0',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path46"
            />

            <path
              d="m 55.408,271.071 h 86.417 v 70.998 H 55.408 Z"
              style={{
                fill: '#e7e7e7',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path48"
            />

            <path
              d="m 57.521,340.367 h 5.877 v -67.594 h -5.877 z m 83.074,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.876 v 67.594 h 5.876 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 11.278,67.594 h 5.877 v -67.594 h -5.877 z m -2.701,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,67.594 v -67.594 h -3.474 v -3.71 h 94.538 v 3.71 h -2.59 v 67.594 h 2.59 v 3.71 H 51.347 v -3.71 z"
              style={{
                fill: '#f5f5f5',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path50"
            />

            <path
              d="m 159.946,120.822 h 86.417 v 70.997 h -86.417 z"
              style={{
                fill: '#e1e1e1',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path52"
            />
            <path
              d="m 162.06,190.117 h 5.877 v -67.594 h -5.877 z m 83.073,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.876 v 67.594 h 5.876 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 11.278,67.594 h 5.877 v -67.594 h -5.877 z m -2.7,-67.594 h -5.877 v 67.594 h 5.877 z m -68.62,67.594 v -67.594 h -3.474 v -3.71 h 94.538 v 3.71 h -2.59 v 67.594 h 2.59 v 3.71 h -94.538 v -3.71 z"
              style={{
                fill: '#ececec',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path54"
            />
            <path
              d="m 159.946,196.182 h 86.417 v 70.998 h -86.417 z"
              style={{
                fill: '#e1e1e1',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path56"
            />
            <path
              d="m 162.06,265.478 h 5.877 v -67.594 h -5.877 z m 83.073,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.876 v 67.594 h 5.876 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 11.278,67.594 h 5.877 v -67.594 h -5.877 z m -2.7,-67.594 h -5.877 v 67.594 h 5.877 z m -68.62,67.594 v -67.594 h -3.474 v -3.71 h 94.538 v 3.71 h -2.59 v 67.594 h 2.59 v 3.709 h -94.538 v -3.709 z"
              style={{
                fill: '#f0f0f0',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path58"
            />
            <path
              d="m 159.946,271.071 h 86.417 v 70.998 h -86.417 z"
              style={{
                fill: '#e7e7e7',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path60"
            />
            <path
              d="m 162.06,340.367 h 5.877 v -67.594 h -5.877 z m 83.073,-67.594 h -5.877 v 67.594 h 5.877 z m -68.619,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.876 v 67.594 h 5.876 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 8.578,0 h -5.877 v 67.594 h 5.877 z m 8.577,0 h -5.877 v 67.594 h 5.877 z m 11.278,67.594 h 5.877 v -67.594 h -5.877 z m -2.7,-67.594 h -5.877 v 67.594 h 5.877 z m -68.62,67.594 v -67.594 h -3.474 v -3.71 h 94.538 v 3.71 h -2.59 v 67.594 h 2.59 v 3.71 h -94.538 v -3.71 z"
              style={{
                fill: '#f5f5f5',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path62"
            />
            <g id="g64" transform="translate(224.1982,408.459)">
              <path
                d="m 0,0 c 0,-13.008 10.545,-23.554 23.554,-23.554 13.008,0 23.553,10.546 23.553,23.554 0,13.009 -10.545,23.554 -23.553,23.554 C 10.545,23.554 0,13.009 0,0"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path66"
              />
            </g>
            <g id="g68" transform="translate(227.7246,408.459)">
              <path
                d="m 0,0 c 0,-11.061 8.967,-20.027 20.027,-20.027 11.061,0 20.027,8.966 20.027,20.027 0,11.061 -8.966,20.027 -20.027,20.027 C 8.967,20.027 0,11.061 0,0"
                style={{
                  fill: '#f0f0f0',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path70"
              />
            </g>
            <g id="g72" transform="translate(248.6968,422.1978)">
              <path
                d="m 0,0 v -14 l 9.278,5.25"
                style={{
                  fill: '#f0f0f0',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: '#e1e1e1',
                  strokeWidth: 2,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: 10,
                  strokeDasharray: 'none',
                  strokeOpacity: 1,
                }}
                id="path74"
              />
            </g>
            <path
              d="m 151.459,369.066 h -46.252 v 61.261 h 46.252 z"
              style={{
                fill: '#ececec',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path76"
            />
            <path
              d="m 149.493,370.959 h -42.314 v 57.48 h 42.314 z"
              style={{
                fill: '#f5f5f5',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path78"
            />
            <g id="g80" transform="translate(134.0889,391.5391)">
              <path
                d="M 0,0 C 0.812,3.572 8.471,5.236 11.05,2.311 13.628,-0.614 10.91,-6.053 6.139,-6.261 1.596,-6.459 -1.228,-5.402 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path82"
              />
            </g>
            <g id="g84" transform="translate(138.7344,397.6538)">
              <path
                d="M 0,0 C -2.288,-0.033 -2.959,5.408 -1.168,5.875 0.623,6.342 3.037,0.044 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path86"
              />
            </g>
            <g id="g88" transform="translate(143.4067,397.6538)">
              <path
                d="M 0,0 C -0.817,0.431 -2.492,5.061 -0.467,5.351 1.558,5.641 4.05,-2.137 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path90"
              />
            </g>
            <g id="g92" transform="translate(133.5166,395.3501)">
              <path
                d="M 0,0 C 0.479,0.302 1.246,4.742 -1.636,5.253 -4.517,5.764 -2.726,-1.714 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path94"
              />
            </g>
            <g id="g96" transform="translate(132.1963,389.666)">
              <path
                d="M 0,0 C 0.985,1.545 -0.393,4.283 -2.262,4.05 -4.131,3.816 -1.787,-2.803 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path98"
              />
            </g>
            <g id="g100" transform="translate(118.2388,392.6396)">
              <path
                d="M 0,0 C 0.376,1.654 3.923,2.425 5.117,1.07 6.312,-0.285 5.053,-2.803 2.843,-2.9 0.739,-2.992 -0.568,-2.502 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path102"
              />
            </g>
            <g id="g104" transform="translate(120.3901,395.4712)">
              <path
                d="M 0,0 C -1.06,-0.015 -1.37,2.504 -0.541,2.721 0.289,2.937 1.407,0.021 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path106"
              />
            </g>
            <g id="g108" transform="translate(122.5542,395.4712)">
              <path
                d="M 0,0 C -0.378,0.2 -1.154,2.344 -0.216,2.478 0.722,2.613 1.875,-0.989 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path110"
              />
            </g>
            <g id="g112" transform="translate(117.9741,394.4043)">
              <path
                d="M 0,0 C 0.222,0.14 0.577,2.196 -0.758,2.433 -2.092,2.669 -1.263,-0.794 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path114"
              />
            </g>
            <g id="g116" transform="translate(117.3623,391.772)">
              <path
                d="M 0,0 C 0.456,0.716 -0.182,1.983 -1.047,1.875 -1.913,1.768 -0.828,-1.298 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path118"
              />
            </g>
            <g id="g120" transform="translate(122.1489,406.6943)">
              <path
                d="M 0,0 C 0.376,1.654 3.923,2.425 5.117,1.07 6.312,-0.284 5.053,-2.803 2.843,-2.9 0.739,-2.992 -0.568,-2.502 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path122"
              />
            </g>
            <g id="g124" transform="translate(124.3003,409.5259)">
              <path
                d="M 0,0 C -1.06,-0.015 -1.371,2.504 -0.541,2.721 0.289,2.938 1.407,0.021 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path126"
              />
            </g>
            <g id="g128" transform="translate(126.4644,409.5259)">
              <path
                d="M 0,0 C -0.378,0.2 -1.154,2.344 -0.216,2.479 0.722,2.613 1.875,-0.989 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path130"
              />
            </g>
            <g id="g132" transform="translate(121.8838,408.4595)">
              <path
                d="M 0,0 C 0.222,0.14 0.577,2.196 -0.757,2.433 -2.092,2.669 -1.262,-0.794 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path134"
              />
            </g>
            <g id="g136" transform="translate(121.2725,405.8271)">
              <path
                d="M 0,0 C 0.456,0.715 -0.182,1.983 -1.047,1.875 -1.913,1.767 -0.828,-1.298 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path138"
              />
            </g>
            <g id="g140" transform="translate(136.5527,411.0449)">
              <path
                d="M 0,0 C 0.376,1.654 3.923,2.425 5.117,1.07 6.311,-0.284 5.052,-2.803 2.843,-2.9 0.739,-2.992 -0.568,-2.502 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path142"
              />
            </g>
            <g id="g144" transform="translate(138.7041,413.8765)">
              <path
                d="M 0,0 C -1.06,-0.015 -1.371,2.504 -0.541,2.721 0.289,2.937 1.407,0.021 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path146"
              />
            </g>
            <g id="g148" transform="translate(140.8682,413.8765)">
              <path
                d="M 0,0 C -0.378,0.2 -1.154,2.344 -0.216,2.478 0.721,2.613 1.875,-0.99 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path150"
              />
            </g>
            <g id="g152" transform="translate(136.2876,412.8096)">
              <path
                d="M 0,0 C 0.222,0.14 0.577,2.196 -0.757,2.433 -2.092,2.669 -1.262,-0.794 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path154"
              />
            </g>
            <g id="g156" transform="translate(135.6763,410.1772)">
              <path
                d="M 0,0 C 0.456,0.716 -0.182,1.983 -1.048,1.875 -1.913,1.768 -0.828,-1.298 0,0"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path158"
              />
            </g>
            <path
              d="m 364.729,303.903 h -60.501 v 80.135 h 60.501 z"
              style={{
                fill: '#f5f5f5',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path160"
            />
            <path
              d="m 362.157,306.379 h -55.351 v 75.189 h 55.351 z"
              style={{
                fill: '#e1e1e1',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path162"
            />
            <g id="g164" transform="translate(319.6807,308.9858)">
              <path
                d="M 0,0 H 9.129 V 31.244 C 4.087,31.244 0,27.156 0,22.115 Z"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path166"
              />
            </g>
            <g id="g168" transform="translate(317.144,308.9858)">
              <path
                d="m 0,0 h 11.666 v 2.669 c 0,2.485 -2.014,4.498 -4.499,4.498 C 3.209,7.167 0,3.958 0,0"
                style={{
                  fill: '#f5f5f5',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path170"
              />
            </g>
            <path
              d="m 346.054,309.939 h -20.733 v 34.034 h 20.733 z"
              style={{
                fill: '#f5f5f5',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path172"
            />
            <g id="g174" transform="translate(350.4946,309.939)">
              <path
                d="M 0,0 H -8.88 V 30.393 C -3.976,30.393 0,26.417 0,21.512 Z"
                style={{
                  fill: '#ececec',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path176"
              />
            </g>
            <g id="g178" transform="translate(353.6743,308.9858)">
              <path
                d="m 0,0 h -11.666 v 2.669 c 0,2.485 2.014,4.498 4.498,4.498 C -3.209,7.167 0,3.958 0,0"
                style={{
                  fill: '#f5f5f5',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path180"
              />
            </g>
            <g id="g182" transform="translate(323.8594,365.0469)">
              <path
                d="M 0,0 C -2.775,2.147 -4.718,6.664 -6.299,12.209 1.581,11.358 7.071,8.902 8.962,4.026 Z"
                style={{
                  fill: '#f0f0f0',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path184"
              />
            </g>
            <g id="g186" transform="translate(319.1304,375.7622)">
              <path
                d="M 0,0 C 0.573,-3.226 2.149,-6.451 4.729,-9.676 L 9.21,-6.754 Z"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path188"
              />
            </g>
            <g id="g190" transform="translate(315.7417,350.5649)">
              <path
                d="m 0,0 c 0,5.113 6.047,14.807 9.957,17.404 3.909,2.598 13.511,3.507 17.298,0.65 3.787,-2.858 6.125,-14.287 6.125,-18.703 0,-4.416 -0.989,-5.097 -3.287,-7.534 -2.298,-2.436 -11.557,-0.779 -16.928,0 C 7.793,-7.403 0,-5.113 0,0"
                style={{
                  fill: '#f0f0f0',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path192"
              />
            </g>
            <g id="g194" transform="translate(335.4722,358.3579)">
              <path
                d="m 0,0 c 0.2,-1.232 -0.138,-2.313 -0.754,-2.413 -0.617,-0.1 -1.278,0.819 -1.478,2.052 -0.2,1.232 0.138,2.313 0.754,2.412 C -0.861,2.151 -0.2,1.233 0,0"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path196"
              />
            </g>
            <g id="g198" transform="translate(324.0864,359.397)">
              <path
                d="m 0,0 c 0.2,-1.232 -0.138,-2.313 -0.754,-2.413 -0.617,-0.1 -1.279,0.819 -1.478,2.051 -0.2,1.233 0.138,2.314 0.754,2.413 C -0.862,2.151 -0.2,1.233 0,0"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path200"
              />
            </g>
            <g id="g202" transform="translate(319.9917,355.5654)">
              <path
                d="M 0,0 3.868,-0.909 1.805,-3.442 Z"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path204"
              />
            </g>
            <g id="g206" transform="translate(323.4297,350.6299)">
              <path
                d="m 0,0 c -0.509,-0.055 1.85,-4.081 0,-3.735 0,0 0.824,-0.577 1.797,-0.107 0,0 1.124,-2.986 2.327,-2.986 1.203,0 0.223,3.129 0.223,3.129 0,0 2.641,0.251 3.271,1.11 0,0 -6.873,-2.165 -7.618,2.589"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path208"
              />
            </g>
            <g id="g210" transform="translate(338.0615,367.3848)">
              <path
                d="m 0,0 7.553,-11.04 1.37,5.555 C 9.771,-1.205 8.44,3.778 5.28,9.352 2.764,6.188 0.776,3.057 0,0"
                style={{
                  fill: '#f0f0f0',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path212"
              />
            </g>
            <g id="g214" transform="translate(342.6479,373.2295)">
              <path
                d="m 0,0 c -1.284,-0.898 -2.494,-3.94 -3.698,-7.144 l 4.7,-6.869 z"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path216"
              />
            </g>
            <path
              d="m 441.839,303.903 h -60.501 v 80.135 h 60.501 z"
              style={{
                fill: '#f5f5f5',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path218"
            />
            <path
              d="m 439.267,306.379 h -55.351 v 75.189 h 55.351 z"
              style={{
                fill: '#ececec',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path220"
            />
            <g id="g222" transform="translate(398.0923,342.6157)">
              <path
                d="m 0,0 c -1.334,0 -3.311,-13.069 -3.311,-13.069 0,0 -2.77,-1.052 -5.566,-1.652 -2.797,-0.601 -2.797,-7.211 -2.797,-7.211 H 0 c 0,0 3.758,7.661 3.849,9.914 C 3.94,-9.764 3.335,-4.206 2.335,-2.103 1.334,0 1.334,0 0,0"
                style={{
                  fill: '#f5f5f5',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path224"
              />
            </g>
            <g id="g226" transform="translate(400.5122,351.1279)">
              <path
                d="m 0,0 c 0,0 -3.663,-10.515 -2.554,-18.126 1.109,-7.611 7.962,-12.318 7.962,-12.318 h 25.636 c 0,0 5.286,3.388 5.208,6.509 -0.078,3.122 0.801,5.608 -3.105,8.713 -3.905,3.104 -14.123,3.204 -14.123,3.204 l -5.941,10.415 z"
                style={{
                  fill: '#fafafa',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path228"
              />
            </g>
            <g id="g230" transform="translate(410.1763,342.6157)">
              <path
                d="m 0,0 c -2.404,0 -5.964,-13.069 -5.964,-13.069 0,0 -4.99,-1.052 -10.028,-1.652 -5.038,-0.601 -5.038,-7.211 -5.038,-7.211 H 0 c 0,0 6.769,7.661 6.933,9.914 0.164,2.254 -0.925,7.812 -2.727,9.915 C 2.403,0 2.403,0 0,0"
                style={{
                  fill: '#f0f0f0',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path232"
              />
            </g>
            <g id="g234" transform="translate(433.9937,334.5039)">
              <path
                d="m 0,0 c -1.334,0 -3.311,-8.862 -3.311,-8.862 0,0 -2.77,-0.713 -5.566,-1.12 -2.797,-0.408 -2.797,-4.889 -2.797,-4.889 H 0 c 0,0 3.758,5.195 3.849,6.722 C 3.94,-6.621 3.335,-2.852 2.335,-1.426 1.334,0 1.334,0 0,0"
                style={{
                  fill: '#f0f0f0',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path236"
              />
            </g>
            <g id="g238" transform="translate(412.7217,359.0811)">
              <path
                d="M 0,0 14.094,5.845 9.639,-6.123 Z"
                style={{
                  fill: '#fafafa',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path240"
              />
            </g>
            <g id="g242" transform="translate(424.8452,362.2271)">
              <path
                d="m 0,0 -5.076,-7.623 2.016,-1.28 z"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path244"
              />
            </g>
            <g id="g246" transform="translate(419.3647,360.2007)">
              <path
                d="m 0,0 c 4.291,-3.255 8.768,-5.554 4.347,-11.737 0,0 5.111,-3.372 2.217,-5.937 -2.893,-2.566 -9.734,-4.887 -15.021,-3.184 -5.286,1.703 -10.902,8.533 -10.462,13.968 C -18.479,-1.455 -7.3,5.539 0,0"
                style={{
                  fill: '#fafafa',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path248"
              />
            </g>
            <g id="g250" transform="translate(425.168,345.333)">
              <path
                d="m 0,0 -3.383,1.763 0.929,-3.362 z"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path252"
              />
            </g>
            <g id="g254" transform="translate(423.1323,344.8804)">
              <path
                d="m 0,0 c 0,0 -0.941,-2.167 -3.895,-2.538 -2.952,-0.37 -3.484,2.683 -3.484,2.683 0,0 -1.036,-4.218 0.945,-5.028 1.981,-0.811 2.386,1.555 2.386,1.555 0,0 1.642,-0.879 4.243,0.047 0,0 -1.686,-0.168 -2.392,0.392 0,0 2.346,1.297 2.197,2.889"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path256"
              />
            </g>
            <g id="g258" transform="translate(416.8604,352.4595)">
              <path
                d="m 0,0 c -0.358,-0.418 -0.479,-0.902 -0.271,-1.081 0.209,-0.179 0.669,0.014 1.028,0.432 0.358,0.418 0.479,0.902 0.27,1.081 C 0.818,0.611 0.358,0.418 0,0"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path260"
              />
            </g>
            <g id="g262" transform="translate(422.7334,349.4839)">
              <path
                d="m 0,0 c -0.358,-0.418 -0.479,-0.902 -0.271,-1.081 0.209,-0.179 0.669,0.014 1.028,0.432 0.358,0.418 0.479,0.902 0.27,1.081 C 0.818,0.611 0.358,0.418 0,0"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path264"
              />
            </g>
            <g id="g266" transform="translate(404.3774,359.0967)">
              <path
                d="M 0,0 13.324,11.284 12.545,-0.47 3.583,-5.146 Z"
                style={{
                  fill: '#fafafa',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path268"
              />
            </g>
            <g id="g270" transform="translate(415.96,366.6792)">
              <path
                d="M 0,0 -6.477,-11.935 0,-8.555 Z"
                style={{
                  fill: '#e1e1e1',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path272"
              />
            </g>
          </g>
        </g>
        <g id="g274">
          <g id="g276" clipPath="url(#clipPath280)">
            <g id="g282" transform="translate(166.8345,410.0781)">
              <path
                d="m 0,0 c 2.194,2.411 4.543,4.676 7.028,6.771 16.753,14.12 39.036,20.813 60.947,20.749 29.79,-0.088 59.603,-12.706 79.408,-34.959 19.805,-22.254 28.886,-53.994 22.542,-83.101 -1.808,-8.297 -4.793,-16.353 -5.833,-24.781 -1.041,-8.429 0.156,-17.652 5.743,-24.047 9.314,-10.658 26.32,-9.258 39.653,-4.508 13.334,4.75 26.676,12.057 40.719,10.282 14.472,-1.83 25.168,-13.683 28.491,-27.412 3.368,-13.916 -3.396,-30.921 -8.963,-43.813 -12.386,-28.685 -34.119,-53.438 -61.508,-68.619 -23.395,-12.969 -51.062,-19.123 -77.362,-14.245 -26.301,4.879 -50.804,21.533 -62.474,45.602 -6.28,12.951 -10.556,29.438 -24.124,34.242 -8.731,3.092 -18.43,-0.091 -26.608,-4.437 -15.997,-8.501 -29.666,-21.529 -46.855,-27.247 -23.005,-7.654 -49.818,-0.158 -66.944,17.002 -17.126,17.161 -24.602,42.844 -21.366,66.871 2.267,16.828 9.945,33.49 23.54,43.665 12.88,9.639 29.576,12.557 45.652,13.175 8.975,0.345 18.929,0.421 25.502,6.541 10.519,9.792 5.298,26.847 4.188,41.175 C -20.065,-32.489 -12.597,-13.845 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path284"
              />
            </g>
            <g id="g286">
              <g id="g288" />
              <g id="g300">
                <g clipPath="url(#clipPath292)" opacity="0.899994" id="g298">
                  <g transform="translate(166.8345,410.0781)" id="g296">
                    <path
                      d="m 0,0 c 2.194,2.411 4.543,4.676 7.028,6.771 16.753,14.12 39.036,20.813 60.947,20.749 29.79,-0.088 59.603,-12.706 79.408,-34.959 19.805,-22.254 28.886,-53.994 22.542,-83.101 -1.808,-8.297 -4.793,-16.353 -5.833,-24.781 -1.041,-8.429 0.156,-17.652 5.743,-24.047 9.314,-10.658 26.32,-9.258 39.653,-4.508 13.334,4.75 26.676,12.057 40.719,10.282 14.472,-1.83 25.168,-13.683 28.491,-27.412 3.368,-13.916 -3.396,-30.921 -8.963,-43.813 -12.386,-28.685 -34.119,-53.438 -61.508,-68.619 -23.395,-12.969 -51.062,-19.123 -77.362,-14.245 -26.301,4.879 -50.804,21.533 -62.474,45.602 -6.28,12.951 -10.556,29.438 -24.124,34.242 -8.731,3.092 -18.43,-0.091 -26.608,-4.437 -15.997,-8.501 -29.666,-21.529 -46.855,-27.247 -23.005,-7.654 -49.818,-0.158 -66.944,17.002 -17.126,17.161 -24.602,42.844 -21.366,66.871 2.267,16.828 9.945,33.49 23.54,43.665 12.88,9.639 29.576,12.557 45.652,13.175 8.975,0.345 18.929,0.421 25.502,6.541 10.519,9.792 5.298,26.847 4.188,41.175 C -20.065,-32.489 -12.597,-13.845 0,0"
                      style={{
                        fill: '#ffffff',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path294"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g302">
          <g id="g304" clipPath="url(#clipPath308)">
            <g id="g310" transform="translate(443.8892,83.7617)">
              <path
                d="m 0,0 c 0,-6.254 -86.807,-11.323 -193.889,-11.323 -107.082,0 -193.889,5.069 -193.889,11.323 0,6.254 86.807,11.323 193.889,11.323 C -86.807,11.323 0,6.254 0,0"
                style={{
                  fill: '#f5f5f5',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path312"
              />
            </g>
          </g>
        </g>
        <g id="g314">
          <g id="g316" clipPath="url(#clipPath320)">
            <g id="g322" transform="translate(301.9985,256.5674)">
              <path
                d="m 0,0 -23.115,0.349 c 0,0 -0.927,-2.977 -1.531,-7.06 -1.153,-1.491 -2.667,-3.488 -3.782,-3.647 1.112,0.158 2.713,-0.14 3.22,0.859 -1.077,-2.13 -2.165,-3.528 -2.019,-5.916 0.546,0.663 1.093,1.327 1.639,1.991 -1.148,-2.609 -1.559,-5.541 -1.175,-8.374 0.572,0.627 0.897,1.473 0.894,2.322 0.442,-2.313 1.432,-4.523 2.865,-6.399 0.149,0.526 0.29,1.087 0.425,1.66 1.648,-2.667 4.233,-4.707 8.205,-5.472 -3.843,-4.39 -13.777,3.57 -18.989,-1.975 -1.392,-1.481 -2.448,-3.586 -1.575,-5.774 0.872,-2.187 37.981,-0.734 42.382,5.638 C 4.278,-23.665 0,0 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path324"
              />
            </g>
            <g id="g326">
              <g id="g328" />
              <g id="g340">
                <g clipPath="url(#clipPath332)" opacity="0.199997" id="g338">
                  <g transform="translate(301.9985,256.5674)" id="g336">
                    <path
                      d="m 0,0 -23.115,0.349 c 0,0 -0.927,-2.977 -1.531,-7.06 -1.153,-1.491 -2.667,-3.488 -3.782,-3.647 1.112,0.158 2.713,-0.14 3.22,0.859 -1.077,-2.13 -2.165,-3.528 -2.019,-5.916 0.546,0.663 1.093,1.327 1.639,1.991 -1.148,-2.609 -1.559,-5.541 -1.175,-8.374 0.572,0.627 0.897,1.473 0.894,2.322 0.442,-2.313 1.432,-4.523 2.865,-6.399 0.149,0.526 0.29,1.087 0.425,1.66 1.648,-2.667 4.233,-4.707 8.205,-5.472 -3.843,-4.39 -13.777,3.57 -18.989,-1.975 -1.392,-1.481 -2.448,-3.586 -1.575,-5.774 0.872,-2.187 37.981,-0.734 42.382,5.638 C 4.278,-23.665 0,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path334"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g342" transform="translate(245.2495,279.7798)">
              <path
                d="m 0,0 -0.527,-9.736 c 0.593,-5.838 2.532,-11.655 5.967,-17.447 1.968,-8.633 -0.31,-18.884 -3.343,-24.005 -0.641,-1.083 -4.577,-2.688 -5.618,-3.095 -1.063,-0.416 -1.65,-4.01 -0.49,-6.283 1.161,-2.272 10.078,0.306 13.565,0.117 3.488,-0.188 -1.062,-0.808 3.075,0.189 0.824,0.199 2.223,1.345 1.752,3 -1.392,4.892 1.385,15.526 2.494,27.462 3.711,10.666 2.173,18.145 2.173,18.145 z"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path344"
              />
            </g>
            <g id="g346">
              <g id="g348" />
              <g id="g360">
                <g clipPath="url(#clipPath352)" opacity="0.199997" id="g358">
                  <g transform="translate(245.2495,279.7798)" id="g356">
                    <path
                      d="m 0,0 -0.527,-9.736 c 0.593,-5.838 2.532,-11.655 5.967,-17.447 1.968,-8.633 -0.31,-18.884 -3.343,-24.005 -0.641,-1.083 -4.577,-2.688 -5.618,-3.095 -1.063,-0.416 -1.65,-4.01 -0.49,-6.283 1.161,-2.272 10.078,0.306 13.565,0.117 3.488,-0.188 -1.062,-0.808 3.075,0.189 0.824,0.199 2.223,1.345 1.752,3 -1.392,4.892 1.385,15.526 2.494,27.462 3.711,10.666 2.173,18.145 2.173,18.145 z"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path354"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g362" transform="translate(398.334,219.4766)">
              <path
                d="m 0,0 c 1.476,0.581 2.362,0.96 2.362,0.96 0,0 -9.933,6.597 -18.449,8.393 -8.517,1.794 -27.464,-1.397 -33.624,6.275 -3.908,4.866 -6.027,8.199 -7.114,10.13 -1.12,1.989 -3.73,3.919 -5.14,5.715 -2.016,2.566 -3.127,2.505 -5.038,4.611 0,0 -1.57,0.552 0.118,-11.935 1.687,-12.486 5.809,-20.483 14.757,-24.659 4.825,-2.252 19.136,-6.079 28.785,-5.736 4.151,0.147 10.571,1.609 15.305,3.105 -0.427,0.408 -2.056,0.555 -2.841,2.39 2.414,-1.449 4.11,-1.526 6.128,-1.13 0,0 -1.35,0.08 -1.98,1.825 C -4.305,-1.227 -1.781,-0.702 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path364"
              />
            </g>
            <g id="g366">
              <g id="g368" />
              <g id="g380">
                <g clipPath="url(#clipPath372)" opacity="0.100006" id="g378">
                  <g transform="translate(362.7002,220.9316)" id="g376">
                    <path
                      d="m 0,0 c 0.415,-0.997 0.83,-1.993 1.244,-2.99 -1.729,0.177 -3.323,1.337 -4.025,2.928 -0.016,-0.996 -0.032,-1.991 -0.048,-2.986 -6.512,4.664 -13.025,9.327 -19.537,13.991 -0.625,0.448 -3.345,5 -4.105,5.112 -1.394,0.204 -4.987,4.297 -5.546,3.004 -0.033,-0.077 4.274,-9.417 4.245,-9.495 2.41,-5.26 5.999,-9.065 11.278,-11.529 4.825,-2.251 19.136,-6.079 28.785,-5.736 1.423,0.051 2.989,0.246 4.62,0.538 -1.98,1.108 -4.325,1.751 -6.557,2.267 C 6.558,-4.017 2.422,-3.052 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path374"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g382" transform="translate(251.5044,331.4004)">
              <path
                d="m 0,0 c 0,0 -14.401,-15.442 -13.478,-40.881 0.924,-25.438 44.514,-43.549 44.514,-43.549 l 7.075,-15.152 c 2.581,-5.526 8.138,-9.051 14.238,-9.029 l 30.111,0.104 C 90.066,-83.158 64.138,-40.815 51.25,-27.304 38.362,-13.793 36.139,9.161 36.139,9.161 Z"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path384"
              />
            </g>
            <g id="g386">
              <g id="g388" />
              <g id="g400">
                <g clipPath="url(#clipPath392)" opacity="0.100006" id="g398">
                  <g transform="translate(282.54,246.9707)" id="g396">
                    <path
                      d="m 0,0 3.683,17.222 11.816,-16.729 23.866,-7.3 -3.613,-18.181 -20.904,2.18 C 7.892,-20.727 4.543,-9.729 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path394"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g402" transform="translate(286.2227,362.793)">
              <path
                d="m 0,0 c -4.109,5.611 -3.017,5.104 -9.225,8.24 -2.848,1.438 -4.054,2.352 -9.31,4.07 -3.033,0.99 -13.636,0.444 -13.944,-2.732 -2.468,1.18 -11.04,5.505 -13.507,6.685 -3.996,-4.195 -4.77,-14.832 3.975,-19.261 -0.725,0.197 -1.449,0.394 -2.175,0.592 -1.613,0.134 -2.713,-1.843 -2.409,-3.433 0.303,-1.591 1.478,-2.844 2.485,-4.113 4.045,-5.097 5.931,-11.855 5.11,-18.31 -0.384,-3.018 -1.932,-10.863 -0.175,-13.345 2.229,-3.151 7.662,1.835 11.401,2.792 6.198,1.586 12.408,3.49 17.839,6.873 C 1.028,-25.114 8.594,-11.735 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path404"
              />
            </g>
            <g id="g406">
              <g id="g408" />
              <g id="g420">
                <g clipPath="url(#clipPath412)" opacity="0.199997" id="g418">
                  <g transform="translate(256.2778,351.124)" id="g416">
                    <path
                      d="m 0,0 c -2.209,-0.323 -4.39,0.605 -6.435,1.505 -3.408,1.505 -6.825,3.014 -10.233,4.519 -0.121,1.067 0.335,2.248 1.133,2.854 0.368,0.275 0.805,0.429 1.289,0.385 0.729,-0.202 1.453,-0.391 2.182,-0.593 -8.683,4.411 -7.97,15.064 -3.972,19.265 0.718,-0.348 1.445,-0.701 2.176,-1.044 l -1.697,-3.299 C -19.285,14.162 -4.555,6.504 3.753,4.586 4.309,2.431 2.204,0.314 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path414"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g422" transform="translate(257.6001,369.9858)">
              <path
                d="M 0,0 C 0,0 3.121,0.281 4.275,-0.263 5.429,-0.806 6.3,-2.962 6.3,-2.962 c 0,0 0.45,2.849 -1.575,3.899 C 2.7,1.987 0,0 0,0"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path424"
              />
            </g>
            <g id="g426">
              <g id="g428" />
              <g id="g440">
                <g clipPath="url(#clipPath432)" opacity="0.100006" id="g438">
                  <g transform="translate(249.5171,334.356)" id="g436">
                    <path
                      d="M 0,0 C 1.205,1.6 3.144,2.853 5.121,2.534 3.836,2.579 2.836,4.083 3.292,5.285 3.68,6.306 4.995,6.784 5.99,6.406 4.712,7.385 5.045,9.893 6.506,10.864 c 1.628,1.081 3.783,0.785 5.682,0.324 -0.027,0.01 -0.054,0.02 -0.081,0.03 -5.628,2.14 -11.347,3.998 -17.227,5.29 -0.343,0.075 -0.7,0.173 -1.052,0.262 C -2.988,11.924 -1.562,5.931 -2.295,0.175 -2.47,-1.202 -2.885,-3.583 -3.12,-6 c 0.125,0.224 0.245,0.451 0.358,0.679 C -1.868,-3.529 -1.205,-1.6 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path434"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g442" transform="translate(243.8179,377.3506)">
              <path
                d="M 0,0 C -1.26,0.603 -2.521,1.206 -3.781,1.809 -5.07,0.455 -5.597,-1.398 -6.223,-3.154 -3.708,-3.555 -1.503,-2.051 0,0"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path444"
              />
            </g>
            <g id="g446" transform="translate(287.3105,342.3164)">
              <path
                d="m 0,0 c -0.573,-0.765 -1.089,-1.791 -1.497,-3.161 -0.041,-0.141 -0.046,-0.279 -0.026,-0.408 -1.518,-3.391 -4.585,-6.202 -9.153,-8.358 -10.674,-5.035 -26.115,-4.777 -30.057,-3.322 -0.496,0.191 -1.049,-0.064 -1.24,-0.561 -0.164,-0.467 0.041,-0.972 0.468,-1.192 0.034,-0.018 0.068,-0.035 0.107,-0.044 4.682,-1.739 20.628,-1.771 31.549,3.386 5.484,2.593 9.037,6.116 10.552,10.469 0.268,0.775 0.718,1.457 1.297,2.007 z"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path448"
              />
            </g>
            <g id="g450" transform="translate(278.4268,328.145)">
              <path
                d="m 0,0 c -0.182,1.078 -0.933,2.471 -0.158,3.242 -1.653,-1.641 -2.624,-3.07 -4.878,-3.656 0.453,0.706 0.906,1.413 1.358,2.12 -2.075,-1.852 -4.666,-3.118 -7.402,-3.617 0.411,0.719 1.097,1.276 1.885,1.53 -2.273,-0.288 -4.612,-0.039 -6.772,0.722 C -11.02,3.66 -2.881,7.267 3.176,6.045 2.511,4.746 -0.252,1.49 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path452"
              />
            </g>
            <g id="g454" transform="translate(259.0669,324.9409)">
              <path
                d="m 0,0 c -0.018,1.093 -0.549,2.584 0.333,3.229 -1.88,-1.372 -3.057,-2.638 -5.374,-2.876 0.555,0.629 1.109,1.259 1.663,1.89 -2.33,-1.517 -5.083,-2.377 -7.863,-2.456 0.514,0.648 1.277,1.095 2.094,1.227 -2.29,0.058 -4.564,0.658 -6.585,1.737 C -10.339,5.284 -1.75,7.619 4.053,5.497 3.199,4.312 -0.024,1.512 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path456"
              />
            </g>
            <g id="g458" transform="translate(296.2544,344.312)">
              <path
                d="M 0,0 1.25,-1.075 0.283,6.518 2.012,6.251 0.104,11.154 2.406,9.97 -0.128,12.996 c -0.39,1.546 -0.955,5.71 -5.466,8.03 -4.621,2.377 -11.699,3.788 -11.699,3.788 0,0 -1.696,-21.267 0.653,-25.259 2.35,-3.992 7.165,-8.746 12.642,-8.183 2.417,0.249 3.535,4.185 3.998,8.63 z"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path460"
              />
            </g>
            <g id="g462">
              <g id="g464" />
              <g id="g476">
                <g clipPath="url(#clipPath468)" opacity="0.199997" id="g474">
                  <g transform="translate(282.5137,364.2095)" id="g472">
                    <path
                      d="m 0,0 c 0.77,-3.915 -1.033,-14.72 0.368,-18.43 1.4,-3.71 6.273,-8.5 6.273,-8.5 l 3.102,-1.595 c -4.716,-0.508 -8.858,2.677 -12.642,8.182 -1.886,5.406 -1.753,15.201 -0.654,25.26 0,0 2.784,-1.002 3.553,-4.917"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path470"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g478" transform="translate(253.6387,353.3975)">
              <path
                d="m 0,0 c -1.236,0.163 -3.333,0.905 -6.236,2.74 -1.15,1.44 -2.365,3.876 -4.349,5.466 0.143,-0.064 0.283,-0.116 0.425,-0.18 C -6.846,4.482 -2.579,2.583 2.206,1.741 2.206,1.741 3.825,-0.521 0,0"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path480"
              />
            </g>
            <g id="g482" transform="translate(247.4028,356.1377)">
              <path
                d="m 0,0 c -1.072,0.681 -2.252,1.508 -3.541,2.517 -0.031,0.022 -0.057,0.052 -0.081,0.069 -0.128,0.101 -0.264,0.208 -0.408,0.321 -0.152,0.118 -0.304,0.237 -0.458,0.368 -1.113,0.874 -2.498,0.906 -4.097,1.053 -0.855,0.071 -1.74,0.046 -2.558,0.337 -0.809,0.286 -1.555,0.996 -1.548,1.86 0.004,0.843 0.751,1.539 1.564,1.745 0.82,0.199 1.676,-0.002 2.464,-0.285 1.584,-0.57 3.026,-1.476 4.314,-2.519 C -4.188,5.337 -4.052,5.212 -3.927,5.089 -2.193,3.511 -1.064,1.333 0,0"
                style={{
                  fill: '#ff463c',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path484"
              />
            </g>
            <g id="g486" transform="translate(234.3618,300.5957)">
              <path
                d="M 0,0 C 1.091,0.072 2.62,-0.335 3.19,0.597 1.978,-1.39 0.812,-2.667 0.765,-4.995 c 0.582,0.604 1.164,1.208 1.747,1.812 -1.32,-2.447 -1.951,-5.261 -1.803,-8.038 0.604,0.566 0.987,1.362 1.052,2.187 0.245,-2.277 1.03,-4.494 2.271,-6.42 C 6.114,-9.872 7.737,-1.119 5.146,4.49 4.035,3.542 1.509,0.1 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path488"
              />
            </g>
            <g id="g490" transform="translate(239.5854,316.3364)">
              <path
                d="m 0,0 c 1.049,-0.306 2.347,-1.212 3.202,-0.532 -1.821,-1.451 -3.353,-2.251 -4.195,-4.422 0.754,0.368 1.508,0.736 2.262,1.103 -2.079,-1.846 -3.636,-4.274 -4.447,-6.934 0.761,0.325 1.394,0.942 1.737,1.695 -0.55,-2.223 -0.572,-4.576 -0.066,-6.809 3.868,4.53 8.392,12.197 7.88,18.355 C 5.004,1.945 1.452,-0.423 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path492"
              />
            </g>
            <g id="g494" transform="translate(329.688,254.916)">
              <path
                d="m 0,0 -23.115,0.349 c 0,0 -0.927,-2.978 -1.531,-7.061 -1.153,-1.491 -2.667,-3.488 -3.782,-3.647 1.112,0.158 2.713,-0.14 3.22,0.86 -1.078,-2.131 -2.165,-3.528 -2.02,-5.917 0.547,0.664 1.094,1.328 1.64,1.991 -1.149,-2.609 -1.56,-5.541 -1.176,-8.373 0.572,0.626 0.898,1.472 0.895,2.322 0.442,-2.314 1.432,-4.524 2.865,-6.4 0.149,0.527 0.289,1.087 0.424,1.66 1.649,-2.667 4.234,-4.706 8.205,-5.472 -3.842,-4.39 -13.776,3.57 -18.988,-1.975 -1.392,-1.481 -2.448,-3.585 -1.575,-5.774 0.872,-2.187 37.981,-0.734 42.381,5.638 C 4.278,-23.666 0,0 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path496"
              />
            </g>
            <g id="g498">
              <g id="g500" />
              <g id="g512">
                <g clipPath="url(#clipPath504)" opacity="0.100006" id="g510">
                  <g transform="translate(273.1069,251.7041)" id="g508">
                    <path
                      d="m 0,0 -0.889,22.719 -5.267,3.222 c -5.718,4.041 -11.929,8.21 -15.135,11.601 L -26.71,47.75 C -32.299,44.522 -34.945,39.741 -34.713,33.438 -28.736,19.502 -16.997,8.439 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path506"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g514" transform="translate(277.9258,216.9365)">
              <path
                d="M 0,0 C -0.46,-0.161 -0.019,-0.106 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path516"
              />
            </g>
            <g id="g518" transform="translate(285.9995,265.9922)">
              <path
                d="m 0,0 -19.048,11.653 -0.051,-0.937 c -0.062,0.101 -0.117,0.209 -0.181,0.308 -0.958,-1.103 -2.944,-4.882 -4.421,-5.205 1.068,0.234 2.64,0.058 3.066,1.065 -0.905,-2.146 -1.867,-3.581 -1.568,-5.891 0.486,0.685 0.973,1.368 1.458,2.052 -0.942,-2.616 -1.147,-5.493 -0.588,-8.217 0.514,0.65 0.774,1.494 0.715,2.319 0.581,-2.215 1.687,-4.292 3.2,-6.011 0.063,0.296 0.121,0.604 0.178,0.915 0.941,-2.531 2.136,-5.059 3.632,-7.581 1.968,-8.632 -0.311,-18.883 -3.344,-24.005 -0.641,-1.083 -4.577,-2.688 -5.617,-3.095 -1.063,-0.416 -1.651,-4.011 -0.49,-6.282 1.161,-2.273 10.078,0.306 13.565,0.116 1.184,-0.063 1.435,-0.177 1.42,-0.26 0.241,0.084 0.719,0.224 1.655,0.45 0.824,0.198 2.223,1.345 1.752,2.999 -1.393,4.893 1.385,15.527 2.494,27.461 C 1.538,-7.479 0,0 0,0"
                style={{
                  fill: '#F6B200',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path520"
              />
            </g>
          </g>
        </g>
        <path
          d="M 412.912,208.889 H 208.941 v 7.676 h 203.971 z"
          style={{
            fill: '#3BB273',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          id="path522"
        />
        <g id="g524">
          <g id="g526" clipPath="url(#clipPath530)">
            <g id="g532">
              <g id="g534" />
              <g id="g544">
                <g clipPath="url(#clipPath538)" opacity="0.600006" id="g542">
                  <path
                    d="M 412.912,208.889 H 208.941 v 7.676 h 203.971 z"
                    style={{
                      fill: '#ffffff',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path540"
                  />
                </g>
              </g>
            </g>
            <g id="g546">
              <g id="g548" />
              <g id="g558">
                <g clipPath="url(#clipPath552)" opacity="0.199997" id="g556">
                  <path
                    d="M 412.912,212.727 H 208.941 v 3.838 h 203.971 z"
                    style={{
                      fill: '#000000',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path554"
                  />
                </g>
              </g>
            </g>
            <g id="g560" transform="translate(416.709,215.5635)">
              <path
                d="m 0,0 h -211.565 c -1.195,0 -2.163,0.968 -2.163,2.162 0,1.194 0.968,2.162 2.163,2.162 H 0 C 1.194,4.324 2.162,3.356 2.162,2.162 2.162,0.968 1.194,0 0,0"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path562"
              />
            </g>
            <g id="g564">
              <g id="g566" />
              <g id="g578">
                <g clipPath="url(#clipPath570)" opacity="0.600006" id="g576">
                  <g transform="translate(416.709,215.5635)" id="g574">
                    <path
                      d="m 0,0 h -211.565 c -1.195,0 -2.163,0.968 -2.163,2.162 0,1.194 0.968,2.162 2.163,2.162 H 0 C 1.194,4.324 2.162,3.356 2.162,2.162 2.162,0.968 1.194,0 0,0"
                      style={{
                        fill: '#ffffff',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path572"
                    />
                  </g>
                </g>
              </g>
            </g>
            <path
              d="m 208.94,86.858 h 10.284 v 122.03 H 208.94 Z"
              style={{
                fill: '#3BB273',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path580"
            />
            <g id="g582">
              <g id="g584" />
              <g id="g594">
                <g clipPath="url(#clipPath588)" opacity="0.5" id="g592">
                  <path
                    d="m 208.94,86.858 h 10.284 V 208.979 H 208.94 Z"
                    style={{
                      fill: '#ffffff',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path590"
                  />
                </g>
              </g>
            </g>
            <g id="g596">
              <g id="g598" />
              <g id="g608">
                <g clipPath="url(#clipPath602)" opacity="0.199997" id="g606">
                  <path
                    d="m 208.94,192.404 h 10.284 v 16.575 H 208.94 Z"
                    style={{
                      fill: '#000000',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path604"
                  />
                </g>
              </g>
            </g>
            <path
              d="m 402.628,86.858 h 10.283 v 122.03 h -10.283 z"
              style={{
                fill: '#3BB273',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path610"
            />
            <g id="g612">
              <g id="g614" />
              <g id="g624">
                <g clipPath="url(#clipPath618)" opacity="0.5" id="g622">
                  <path
                    d="m 402.628,86.858 h 10.283 v 122.121 h -10.283 z"
                    style={{
                      fill: '#ffffff',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path620"
                  />
                </g>
              </g>
            </g>
            <g id="g626">
              <g id="g628" />
              <g id="g638">
                <g clipPath="url(#clipPath632)" opacity="0.199997" id="g636">
                  <path
                    d="m 402.628,192.404 h 10.283 v 16.575 h -10.283 z"
                    style={{
                      fill: '#000000',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path634"
                  />
                </g>
              </g>
            </g>
            <path
              d="m 271.879,86.858 h 10.284 v 122.03 h -10.284 z"
              style={{
                fill: '#3BB273',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path640"
            />
            <g id="g642">
              <g id="g644" />
              <g id="g654">
                <g clipPath="url(#clipPath648)" opacity="0.5" id="g652">
                  <path
                    d="m 271.879,86.858 h 10.284 v 122.121 h -10.284 z"
                    style={{
                      fill: '#ffffff',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path650"
                  />
                </g>
              </g>
            </g>
            <g id="g656">
              <g id="g658" />
              <g id="g668">
                <g clipPath="url(#clipPath662)" opacity="0.199997" id="g666">
                  <path
                    d="m 271.879,184.979 h 10.284 v 24 h -10.284 z"
                    style={{
                      fill: '#000000',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path664"
                  />
                </g>
              </g>
            </g>
            <path
              d="m 339.852,86.858 h 10.284 v 122.03 h -10.284 z"
              style={{
                fill: '#3BB273',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path670"
            />
            <g id="g672">
              <g id="g674" />
              <g id="g684">
                <g clipPath="url(#clipPath678)" opacity="0.5" id="g682">
                  <path
                    d="m 339.852,86.858 h 10.284 v 122.121 h -10.284 z"
                    style={{
                      fill: '#ffffff',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path680"
                  />
                </g>
              </g>
            </g>
            <g id="g686">
              <g id="g688" />
              <g id="g698">
                <g clipPath="url(#clipPath692)" opacity="0.199997" id="g696">
                  <path
                    d="m 339.852,184.979 h 10.284 v 24 h -10.284 z"
                    style={{
                      fill: '#000000',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                    id="path694"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g700">
          <g id="g702" clipPath="url(#clipPath706)">
            <g id="g708" transform="translate(214.7578,311.3848)">
              <path
                d="m 0,0 15.443,8.975 c 0,0 3.398,-0.343 7.37,0.342 3.973,0.685 4.861,0.726 4.382,3.874 -0.48,3.148 0.187,7.35 -1.001,7.795 -1.189,0.446 -9.608,-3.115 -11.066,-5.518 L -0.459,9.891 Z"
                style={{
                  fill: '#fe8a7b',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path710"
              />
            </g>
            <g id="g712" transform="translate(169.0225,326.6299)">
              <path
                d="m 0,0 v -18.01 c 0,0 3.84,-1.27 8.61,-2.6 5.47,-1.52 12.16,-3.12 15.64,-2.95 6.5,0.32 36.587,15.705 36.587,15.705 L 58.662,0 C 58.662,0 24.75,-12.38 21.75,-11.79 18.75,-11.2 0,0 0,0"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path714"
              />
            </g>
            <g id="g716">
              <g id="g718" />
              <g id="g730">
                <g clipPath="url(#clipPath722)" opacity="0.199997" id="g728">
                  <g transform="translate(169.0225,317.8599)" id="g726">
                    <path
                      d="m 0,0 v -9.24 c 0,0 3.84,-1.27 8.61,-2.6 C 6.85,-6.48 2.46,-2.11 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path724"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g732" transform="translate(164.9834,262.5605)">
              <path
                d="m 0,0 c 0,0 10.837,-57.765 13.729,-81.48 3.778,-30.94 22.347,-84.831 22.347,-84.831 h -5.925 c 0,0 -25.152,45.495 -29.398,80.104 -17.301,35.368 -18.698,78.923 -18.698,78.923 z"
                style={{
                  fill: '#fe8a7b',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path734"
              />
            </g>
            <g id="g736" transform="translate(153.728,263.4824)">
              <path
                d="m 0,0 c 0,0 -1.345,-58.757 -3.421,-82.559 -2.703,-31.051 4.027,-85.273 4.027,-85.273 h -5.549 c 0,0 -15.156,48.594 -12.152,83.333 -9.611,38.183 -1.968,81.084 -1.968,81.084 z"
                style={{
                  fill: '#fe8a7b',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path738"
              />
            </g>
            <g id="g740" transform="translate(150.7485,320.0112)">
              <path
                d="m 0,0 c -8.135,1.041 -10.58,4.516 -10.58,4.516 5.162,4.87 0.874,13.308 0.874,13.308 L 0.085,15.996 C -0.172,14.378 -0.282,13.021 -0.297,11.874 -0.372,5.931 2.316,5.873 2.316,5.873 4.035,2.653 0,0 0,0"
                style={{
                  fill: '#fe8a7b',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path742"
              />
            </g>
            <g id="g744" transform="translate(168.8525,277.1499)">
              <path
                d="m 0,0 -33.01,-2.85 c -0.52,8.2 -1.04,14.46 -3.51,24.24 -0.79,3.16 -1.78,6.69 -3.05,10.77 -0.68,2.18 -1.43,4.52 -2.27,7.04 -0.63,1.91 -1.31,3.94 -2.06,6.08 4.49,1.43 9.06,2.54 13.7,3.31 4.89,0.87 9.85,1.39 14.82,1.56 6.42,0.05 13.8,-0.67 13.8,-0.67 0,0 1.05,-0.25 1.71,-7.06 C 0.73,36.03 1.32,23.25 0,0"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path746"
              />
            </g>
            <g id="g748" transform="translate(168.6943,272.2012)">
              <path
                d="m 0,0 -32.982,-2.721 c -0.52,8.2 -0.91,19.28 -3.38,29.06 -0.79,3.16 -1.78,6.69 -3.05,10.77 -0.68,2.18 -1.43,4.52 -2.27,7.04 -0.63,1.91 -1.31,3.94 -2.06,6.08 4.49,1.43 9.06,2.54 13.7,3.31 4.89,0.87 9.85,1.39 14.82,1.56 6.42,0.05 13.8,-0.67 13.8,-0.67 0,0 1.05,-0.25 1.71,-7.06 C 0.888,40.979 1.32,23.25 0,0"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path750"
              />
            </g>
            <g id="g752">
              <g id="g754" />
              <g id="g766">
                <g clipPath="url(#clipPath758)" opacity="0.199997" id="g764">
                  <g transform="translate(150.834,336.0073)" id="g762">
                    <path
                      d="m 0,0 c -0.257,-1.618 -0.367,-2.976 -0.383,-4.123 -6.614,0 -9.409,5.951 -9.409,5.951 z"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path760"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g768" transform="translate(168.6943,272.2012)">
              <path
                d="m 0,0 20.801,-122.224 c 0,0 -50.342,-6.008 -67.407,-2.493 0,0 -4.186,87.476 13.624,121.996 z"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path770"
              />
            </g>
            <g id="g772">
              <g id="g774" />
              <g id="g786">
                <g clipPath="url(#clipPath778)" opacity="0.399994" id="g784">
                  <g transform="translate(168.6943,272.2012)" id="g782">
                    <path
                      d="m 0,0 20.801,-122.224 c 0,0 -50.342,-6.008 -67.407,-2.493 0,0 -4.186,87.476 13.624,121.996 z"
                      style={{
                        fill: '#ffffff',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path780"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g788" transform="translate(124.9526,322.4302)">
              <path
                d="m 0,0 12.012,3.13 12.907,-54.912 -3.593,-136.153 -31.5,5.674 c 2.6,49.969 6.776,96.88 17.006,132.032 z"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path790"
              />
            </g>
            <g id="g792" transform="translate(169.0225,326.6299)">
              <path
                d="m 0,0 c 6.062,-16.999 5.197,-35.463 1.962,-54.429 l 31.5,-120.001 -22.5,-14.7 -14.833,134.701 c -0.295,19.149 0.207,38.479 -10.817,55.176 z"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path794"
              />
            </g>
            <g id="g796" transform="translate(192.9136,98.2539)">
              <path
                d="m 0,0 c 0.032,0.725 8.925,-0.032 8.925,-0.032 0,0 5.361,-5.689 10.657,-7.053 5.295,-1.365 2.253,-4.495 -3.695,-4.37 -5.948,0.127 -10.488,0.223 -14.714,0.311 C -3.053,-11.054 -0.335,-7.51 0,0"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path798"
              />
            </g>
            <g id="g800" transform="translate(146.333,98.2539)">
              <path
                d="m 0,0 c 0.033,0.725 8.926,-0.032 8.926,-0.032 0,0 5.36,-5.689 10.656,-7.053 5.295,-1.365 2.253,-4.495 -3.695,-4.37 C 9.939,-11.328 5.4,-11.232 1.174,-11.144 -3.053,-11.054 -0.335,-7.51 0,0"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path802"
              />
            </g>
            <g id="g804" transform="translate(141.0425,326.6299)">
              <path
                d="m 0,0 8.829,-2.455 3.138,3.75 z"
                style={{
                  fill: '#fe8a7b',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path806"
              />
            </g>
            <g id="g808" transform="translate(149.8716,324.1753)">
              <path
                d="m 0,0 -4.84,-6.141 -8.129,7.073 4.14,4.839 z"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path810"
              />
            </g>
            <g id="g812" transform="translate(149.8716,324.1753)">
              <path
                d="M 0,0 5.65,-4.175 4.463,3.201 1.199,6.944 Z"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path814"
              />
            </g>
            <g id="g816">
              <g id="g818" />
              <g id="g830">
                <g clipPath="url(#clipPath822)" opacity="0.199997" id="g828">
                  <g transform="translate(133.0713,324.5239)" id="g826">
                    <path
                      d="m 0,0 -0.912,-14.599 5.488,-4.95 -4.5,-4.174 L 16.421,-68.251 16.8,-53.876 3.831,1.526 Z"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path824"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g832">
              <g id="g834" />
              <g id="g846">
                <g clipPath="url(#clipPath838)" opacity="0.199997" id="g844">
                  <g transform="translate(154.3345,327.3765)" id="g842">
                    <path
                      d="m 0,0 3,-0.126 10.575,-12.938 -2.758,-4.5 3.871,-3.038 0.424,-73.579 -4.295,39.006 C 10.425,-28.985 8.46,-11.87 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path840"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g848">
              <g id="g850" />
              <g id="g862">
                <g clipPath="url(#clipPath854)" opacity="0.199997" id="g860">
                  <g transform="translate(129.4912,289.0591)" id="g858">
                    <path
                      d="M 0,0 2.668,13.011 -0.885,6.505 Z"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path856"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g864">
              <g id="g866" />
              <g id="g878">
                <g clipPath="url(#clipPath870)" opacity="0.100006" id="g876">
                  <g transform="translate(129.1987,262.5605)" id="g874">
                    <path
                      d="m 0,0 7.704,-20.837 -1.569,-1.389 0.851,-1.884 c 0,0 6.268,-11.776 4.858,-13.5 -1.41,-1.726 -10.934,1.331 -10.934,1.331 l -3.724,23.718 z"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path872"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g880" transform="translate(120.5845,254.0791)">
              <path
                d="m 0,0 7.495,-15.843 c 0,0 -0.5,-3.378 0,-7.378 0.5,-4 0.5,-4.889 3.667,-4.555 3.167,0.333 7.333,-0.529 7.833,0.637 0.5,1.167 -2.666,9.743 -5,11.311 L 9.146,0 Z"
                style={{
                  fill: '#fe8a7b',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path882"
              />
            </g>
            <g id="g884" transform="translate(122.1748,281.1108)">
              <path
                d="m 0,0 c 1.949,2.645 3.82,6.187 5.393,9.609 2.7,5.85 4.592,11.35 4.592,11.35 l -1.994,5.64 -5.221,14.72 c 0,0 -3.453,-4.809 -8.685,-22.86 l -0.028,0.088 -0.967,-3.563 c -0.757,-2.632 -1.592,-5.499 -2.375,-8.645 0,0 0.003,-0.036 0.005,-0.088 L -9.866,4.095 C -9.95,3.931 -10.042,3.764 -10.152,3.589 v -0.01 c 0,-0.179 -0.002,-0.363 -0.003,-0.551 l -1.852,-6.823 14.952,-35.592 h 9.674 z"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path886"
              />
            </g>
            <g id="g888" transform="translate(130.0825,358.8804)">
              <path
                d="M 0,0 C 1.238,3.067 4.535,3.067 4.535,3.067 H 9.52 l -3.782,-9.881 0.224,-12.379 -1.671,3.77 -0.466,-3.77 c 0,0 -4.028,8.581 -4.275,11.113 C -0.697,-5.548 -1.237,-3.067 0,0"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path890"
              />
            </g>
            <g id="g892" transform="translate(145.4209,364.2251)">
              <path
                d="M 0,0 C 8.512,0.466 11.501,-3.94 12.605,-12.678 13.984,-23.596 11.98,-31.517 1.21,-29.759 -13.417,-27.371 -14.135,-0.774 0,0"
                style={{
                  fill: '#fe8a7b',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path894"
              />
            </g>
            <g id="g896" transform="translate(152.4976,351.7222)">
              <path
                d="m 0,0 c 0,0 1.301,-2.159 2.67,-3.395 0,0 -0.665,-1.197 -2.191,-1.085 z"
                style={{
                  fill: '#b94d3c',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path898"
              />
            </g>
            <g id="g900" transform="translate(147.25,352.1328)">
              <path
                d="m 0,0 c 0.055,-0.699 -0.279,-1.294 -0.744,-1.331 -0.465,-0.036 -0.887,0.5 -0.942,1.199 -0.055,0.698 0.278,1.294 0.744,1.33 C -0.477,1.234 -0.055,0.698 0,0"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path902"
              />
            </g>
            <g id="g904" transform="translate(146.1167,353.2822)">
              <path
                d="M 0,0 1.472,0.714 C 1.472,0.714 0.816,-0.623 0,0"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path906"
              />
            </g>
            <g id="g908" transform="translate(155.9189,352.9575)">
              <path
                d="m 0,0 c 0.055,-0.699 -0.278,-1.294 -0.744,-1.331 -0.465,-0.036 -0.887,0.5 -0.942,1.199 -0.054,0.698 0.279,1.294 0.745,1.33 C -0.476,1.234 -0.055,0.698 0,0"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path910"
              />
            </g>
            <g id="g912" transform="translate(154.7856,354.1069)">
              <path
                d="M 0,0 1.472,0.714 C 1.472,0.714 0.816,-0.623 0,0"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path914"
              />
            </g>
            <g id="g916" transform="translate(146.8423,358.0054)">
              <path
                d="M 0,0 C 0,0 -2.409,0.18 -3.621,-1.489"
                style={{
                  fill: 'none',
                  stroke: '#263237',
                  strokeWidth: 0.725,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: 10,
                  strokeDasharray: 'none',
                  strokeOpacity: 1,
                }}
                id="path918"
              />
            </g>
            <g id="g920" transform="translate(153.0093,358.7041)">
              <path
                d="M 0,0 C 0,0 1.473,0.639 3.431,-0.514"
                style={{
                  fill: 'none',
                  stroke: '#263237',
                  strokeWidth: 0.725,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: 10,
                  strokeDasharray: 'none',
                  strokeOpacity: 1,
                }}
                id="path922"
              />
            </g>
            <g id="g924" transform="translate(151.9189,343.8252)">
              <path
                d="m 0,0 c -0.436,-1.053 -0.831,-3.01 -0.831,-3.01 -0.184,0.003 -0.368,0.007 -0.551,0.021 -2.491,0.114 -3.609,0.99 -4.092,1.927 -0.263,0.514 -0.33,1.051 -0.327,1.487 -0.002,0.504 0.112,0.864 0.112,0.864 1.284,-0.85 3.598,-1.136 4.84,-1.237 C -0.329,0.005 0,0 0,0"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path926"
              />
            </g>
            <g id="g928" transform="translate(151.0703,343.8774)">
              <path
                d="m 0,0 -0.268,-0.881 c -2.346,0.113 -3.982,0.508 -4.685,1.254 -0.002,0.504 0.112,0.864 0.112,0.864 C -3.557,0.387 -1.242,0.101 0,0"
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path930"
              />
            </g>
            <g id="g932" transform="translate(150.5371,340.8359)">
              <path
                d="M 0,0 C -2.491,0.115 -3.609,0.991 -4.092,1.927 -3.245,1.856 -2.231,1.675 -1.352,1.252 -0.678,0.931 -0.266,0.465 0,0"
                style={{
                  fill: '#fe5652',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path934"
              />
            </g>
            <g id="g936" transform="translate(136.9048,349.3701)">
              <path
                d="m 0,0 c 0,0 3.597,7.211 2.698,12.577 0,0 16.489,7.815 18.26,-9.26 0,0 0.815,15.292 -13.835,14.829 C -7.504,17.685 -6.292,-3.719 1.613,-9.683 1.613,-9.683 -2.222,-4.508 0,0"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path938"
              />
            </g>
            <g id="g940" transform="translate(138.3936,347.6499)">
              <path
                d="M 0,0 C -0.132,1.367 -1.673,3.609 -4.207,3.191 -6.829,2.759 -7.789,-2.725 -1.157,-3.667 -0.206,-3.802 0.275,-2.853 0,0"
                style={{
                  fill: '#fe8a7b',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path942"
              />
            </g>
            <g id="g944" transform="translate(134.6177,361.9478)">
              <path
                d="m 0,0 c 0,0 -1.385,4.459 2.44,7.047 3.825,2.587 10.522,1.467 14.861,0 4.339,-1.468 8.764,-14.173 8.764,-14.173 -2.639,3.937 -5.161,6.311 -7.673,8.548 L 6.377,1.534 4.985,0 Z"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path946"
              />
            </g>
            <g id="g948" transform="translate(128.3896,357.0503)">
              <path
                d="m 0,0 c -7.663,3.921 -17.453,-3.369 -13.336,-9.261 0.63,-8.675 6.352,-8.95 9.763,-2.971 4.964,3.99 3.652,7.444 1.711,10.845 -0.386,-1.331 0.47,-3.604 -4.48,-5.422 l -1.13,0.705 c 3.448,4.08 5.61,3.003 7.885,2.25 z"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path950"
              />
            </g>
            <g id="g952" transform="translate(111.0103,342.1489)">
              <path
                d="m 0,0 c 1.435,8.897 4.521,14.806 10.352,15.745 l 2.292,0.264 C 6.555,14.713 2.517,8.998 0,0"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path954"
              />
            </g>
            <g id="g956" transform="translate(114.5596,342.8071)">
              <path
                d="M 0,0 C -1.146,8.871 2.254,13.895 9.896,15.327 L 5.971,14.914 C -0.14,12.592 -1.72,7.357 0,0"
                style={{
                  fill: '#263237',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path958"
              />
            </g>
            <g id="g960" transform="translate(128.3335,357.6343)">
              <path
                d="m 0,0 0.439,-4.709 c 0,0 0.265,-0.352 0.748,-0.305 0.483,0.046 0.639,0.428 0.639,0.428 L 1.502,0.082 c 0,0 -0.181,0.519 -0.727,0.499 C 0.229,0.561 0,0 0,0"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path962"
              />
            </g>
            <g id="g964" transform="translate(247.1099,339.7749)">
              <path
                d="M 0,0 C 0.023,-2.588 -0.194,-4.95 -1.875,-5.925 -0.354,-6.817 0.008,-8.948 0,-11.475 H 0.825 V 0 Z"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path966"
              />
            </g>
            <g id="g968">
              <g id="g970" />
              <g id="g982">
                <g clipPath="url(#clipPath974)" opacity="0.600006" id="g980">
                  <g transform="translate(247.1099,339.7749)" id="g978">
                    <path
                      d="M 0,0 C 0.023,-2.588 -0.194,-4.95 -1.875,-5.925 -0.354,-6.817 0.008,-8.948 0,-11.475 H 0.825 V 0 Z"
                      style={{
                        fill: '#ffffff',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path976"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g984" transform="translate(135.228,346.2065)">
              <path
                d="m 0,0 c 0,0 2.194,-10.836 3.893,-12.606 1.698,-1.77 7.852,-4.062 11.949,-2.481 4.097,1.582 7.024,2.468 8.215,5.43 1.191,2.963 -0.874,5.607 -0.874,5.607 l -0.014,2.118 c 0,0 2.875,-4.481 1.863,-7.687 -1.013,-3.206 -4.526,-5.406 -9.19,-6.641 -4.663,-1.234 -11.81,0.728 -13.236,3.178 -1.424,2.451 -3.917,12.726 -3.917,12.726 z"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path986"
              />
            </g>
            <g id="g988">
              <g id="g990" />
              <g id="g1002">
                <g clipPath="url(#clipPath994)" opacity="0.5" id="g1000">
                  <g transform="translate(135.228,346.2065)" id="g998">
                    <path
                      d="m 0,0 c 0,0 2.194,-10.836 3.893,-12.606 1.698,-1.77 7.852,-4.062 11.949,-2.481 4.097,1.582 7.024,2.468 8.215,5.43 1.191,2.963 -0.874,5.607 -0.874,5.607 l -0.014,2.118 c 0,0 2.875,-4.481 1.863,-7.687 -1.013,-3.206 -4.526,-5.406 -9.19,-6.641 -4.663,-1.234 -11.81,0.728 -13.236,3.178 -1.424,2.451 -3.917,12.726 -3.917,12.726 z"
                      style={{
                        fill: '#ffffff',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path996"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1004" transform="translate(200.1899,263.4785)">
              <path
                d="m 0,0 c -15.595,0 -28.395,7.296 -36.866,14.003 -12.42,9.834 -12.746,50.727 -12.754,52.463 l 1,0.005 C -48.618,66.05 -48.29,24.324 -36.245,14.787 -26.436,7.021 -10.733,-1.555 8.36,1.715 24.704,4.51 26.336,7.089 33.203,17.942 l 0.694,1.097 c 5.311,8.375 4.605,26.764 4.183,37.751 -0.18,4.691 -0.31,8.08 0.03,9.421 1.207,4.757 7.205,4.811 7.46,4.811 v -1 c -0.054,0 -5.476,-0.057 -6.49,-4.057 -0.306,-1.201 -0.171,-4.703 -10e-4,-9.137 0.426,-11.103 1.14,-29.686 -4.337,-38.324 L 34.048,17.407 C 26.989,6.25 25.312,3.6 8.529,0.729 5.605,0.229 2.758,0 0,0"
                style={{
                  fill: '#1a2e35',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path1006"
              />
            </g>
            <g id="g1008">
              <g id="g1010" />
              <g id="g1022">
                <g clipPath="url(#clipPath1014)" opacity="0.300003" id="g1020">
                  <g transform="translate(200.1899,263.4785)" id="g1018">
                    <path
                      d="m 0,0 c -15.595,0 -28.395,7.296 -36.866,14.003 -12.42,9.834 -12.746,50.727 -12.754,52.463 l 1,0.005 C -48.618,66.05 -48.29,24.324 -36.245,14.787 -26.436,7.021 -10.733,-1.555 8.36,1.715 24.704,4.51 26.336,7.089 33.203,17.942 l 0.694,1.097 c 5.311,8.375 4.605,26.764 4.183,37.751 -0.18,4.691 -0.31,8.08 0.03,9.421 1.207,4.757 7.205,4.811 7.46,4.811 v -1 c -0.054,0 -5.476,-0.057 -6.49,-4.057 -0.306,-1.201 -0.171,-4.703 -10e-4,-9.137 0.426,-11.103 1.14,-29.686 -4.337,-38.324 L 34.048,17.407 C 26.989,6.25 25.312,3.6 8.529,0.729 5.605,0.229 2.758,0 0,0"
                      style={{
                        fill: '#ffffff',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1016"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g1024">
          <g id="g1026" clipPath="url(#clipPath1030)">
            <g id="g1032" transform="translate(355.5547,180.291)">
              <path
                d="m 0,0 0.93,8.825 c 0.108,1.029 0.969,1.805 2.004,1.805 h 21.6 c 1.034,0 1.895,-0.776 2.004,-1.805 L 27.468,0 l 3.89,0.41 -0.93,8.825 c -0.319,3.026 -2.853,5.307 -5.894,5.307 h -21.6 c -3.042,0 -5.576,-2.281 -5.894,-5.307 L -3.891,0.41 Z"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path1034"
              />
            </g>
            <g id="g1036">
              <g id="g1038" />
              <g id="g1050">
                <g clipPath="url(#clipPath1042)" opacity="0.300003" id="g1048">
                  <g transform="translate(355.5547,180.291)" id="g1046">
                    <path
                      d="m 0,0 0.93,8.825 c 0.108,1.029 0.969,1.805 2.004,1.805 h 21.6 c 1.034,0 1.895,-0.776 2.004,-1.805 L 27.468,0 l 3.89,0.41 -0.93,8.825 c -0.319,3.026 -2.853,5.307 -5.894,5.307 h -21.6 c -3.042,0 -5.576,-2.281 -5.894,-5.307 L -3.891,0.41 Z"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1044"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1052" transform="translate(274.0386,138.6152)">
              <path
                d="M 0,0 V -5.203 C 0,-7.198 1.616,-8.814 3.612,-8.814 H 4.186 L 14.76,-44.386 c 1.174,-3.925 4.785,-6.624 8.88,-6.624 h 143.233 c 4.094,0 7.706,2.699 8.866,6.624 l 10.575,35.572 h 0.574 c 1.995,0 3.612,1.616 3.612,3.611 V 0 c 0,1.995 -1.617,3.611 -3.612,3.611 h -0.574 l -10.575,35.572 c -1.16,3.924 -4.772,6.624 -8.866,6.624 H 23.64 c -4.095,0 -7.706,-2.7 -8.88,-6.624 L 4.186,3.611 H 3.612 C 1.616,3.611 0,1.995 0,0"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path1054"
              />
            </g>
            <g id="g1056">
              <g id="g1058" />
              <g id="g1070">
                <g clipPath="url(#clipPath1062)" opacity="0.300003" id="g1068">
                  <g transform="translate(278.2241,129.8008)" id="g1066">
                    <path
                      d="m 0,0 10.575,-35.571 c 1.174,-3.925 4.785,-6.624 8.88,-6.624 h 143.232 c 4.094,0 7.707,2.699 8.867,6.624 L 182.129,0 Z"
                      style={{
                        fill: '#ffffff',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1064"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1072">
              <g id="g1074" />
              <g id="g1086">
                <g clipPath="url(#clipPath1078)" opacity="0.399994" id="g1084">
                  <g transform="translate(383.6777,152.1973)" id="g1082">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.092,0.921 0.894,1.612 l -6.018,20.978 c -0.198,0.692 -0.921,1.09 -1.613,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.089,0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1080"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1088">
              <g id="g1090" />
              <g id="g1102">
                <g clipPath="url(#clipPath1094)" opacity="0.399994" id="g1100">
                  <g transform="translate(376.042,152.1973)" id="g1098">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.093,0.921 0.894,1.612 l -6.018,20.978 c -0.199,0.692 -0.922,1.09 -1.614,0.894 C -7.07,23.337 -7.47,22.613 -7.271,21.922 L -1.253,0.944 C -1.089,0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1096"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1104">
              <g id="g1106" />
              <g id="g1118">
                <g clipPath="url(#clipPath1110)" opacity="0.399994" id="g1116">
                  <g transform="translate(368.4053,152.1973)" id="g1114">
                    <path
                      d="m 0,0 c 0.12,0 0.24,0.017 0.361,0.051 0.692,0.198 1.092,0.921 0.893,1.612 l -6.017,20.978 c -0.199,0.692 -0.921,1.09 -1.614,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.252,0.944 C -1.088,0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1112"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1120">
              <g id="g1122" />
              <g id="g1134">
                <g clipPath="url(#clipPath1126)" opacity="0.399994" id="g1132">
                  <g transform="translate(360.77,152.1973)" id="g1130">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.092,0.921 0.894,1.612 l -6.018,20.978 c -0.198,0.692 -0.921,1.09 -1.613,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.089,0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1128"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1136">
              <g id="g1138" />
              <g id="g1150">
                <g clipPath="url(#clipPath1142)" opacity="0.399994" id="g1148">
                  <g transform="translate(353.1338,152.1973)" id="g1146">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.361,0.051 0.692,0.198 1.092,0.921 0.893,1.612 l -6.018,20.978 c -0.198,0.692 -0.92,1.09 -1.613,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.088,0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1144"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1152">
              <g id="g1154" />
              <g id="g1166">
                <g clipPath="url(#clipPath1158)" opacity="0.399994" id="g1164">
                  <g transform="translate(345.4985,152.1973)" id="g1162">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.093,0.921 0.894,1.612 l -6.019,20.978 c -0.198,0.692 -0.921,1.09 -1.613,0.894 -0.692,-0.198 -1.093,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.089,0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1160"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1168">
              <g id="g1170" />
              <g id="g1182">
                <g clipPath="url(#clipPath1174)" opacity="0.399994" id="g1180">
                  <g transform="translate(383.6777,119.8301)" id="g1178">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.092,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.198,-0.691 -0.921,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.018,20.978 C -1.089,-0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1176"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1184">
              <g id="g1186" />
              <g id="g1198">
                <g clipPath="url(#clipPath1190)" opacity="0.399994" id="g1196">
                  <g transform="translate(376.042,119.8301)" id="g1194">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.093,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.199,-0.691 -0.922,-1.089 -1.614,-0.893 -0.692,0.198 -1.092,0.921 -0.893,1.612 l 6.018,20.978 C -1.089,-0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1192"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1200">
              <g id="g1202" />
              <g id="g1214">
                <g clipPath="url(#clipPath1206)" opacity="0.399994" id="g1212">
                  <g transform="translate(368.4053,119.8301)" id="g1210">
                    <path
                      d="m 0,0 c 0.12,0 0.24,-0.017 0.361,-0.051 0.692,-0.198 1.092,-0.921 0.893,-1.612 l -6.017,-20.979 c -0.199,-0.691 -0.921,-1.089 -1.614,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.019,20.978 C -1.088,-0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1208"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1216">
              <g id="g1218" />
              <g id="g1230">
                <g clipPath="url(#clipPath1222)" opacity="0.399994" id="g1228">
                  <g transform="translate(360.77,119.8301)" id="g1226">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.092,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.198,-0.691 -0.921,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.018,20.978 C -1.089,-0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1224"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1232">
              <g id="g1234" />
              <g id="g1246">
                <g clipPath="url(#clipPath1238)" opacity="0.399994" id="g1244">
                  <g transform="translate(353.1338,119.8301)" id="g1242">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.361,-0.051 0.692,-0.198 1.092,-0.921 0.893,-1.612 l -6.018,-20.979 c -0.198,-0.691 -0.92,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.018,20.978 C -1.088,-0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1240"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1248">
              <g id="g1250" />
              <g id="g1262">
                <g clipPath="url(#clipPath1254)" opacity="0.399994" id="g1260">
                  <g transform="translate(345.4985,119.8301)" id="g1258">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.093,-0.921 0.894,-1.612 l -6.019,-20.979 c -0.198,-0.691 -0.921,-1.089 -1.613,-0.893 -0.692,0.198 -1.093,0.921 -0.894,1.612 l 6.019,20.978 C -1.089,-0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1256"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1264">
              <g id="g1266" />
              <g id="g1278">
                <g clipPath="url(#clipPath1270)" opacity="0.399994" id="g1276">
                  <g transform="translate(430.4849,152.1973)" id="g1274">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.092,0.921 0.894,1.612 l -6.018,20.978 c -0.198,0.692 -0.921,1.09 -1.613,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.089,0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1272"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1280">
              <g id="g1282" />
              <g id="g1294">
                <g clipPath="url(#clipPath1286)" opacity="0.399994" id="g1292">
                  <g transform="translate(422.8491,152.1973)" id="g1290">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.093,0.921 0.894,1.612 l -6.019,20.978 c -0.199,0.692 -0.921,1.09 -1.613,0.894 C -7.07,23.337 -7.47,22.613 -7.271,21.922 L -1.253,0.944 C -1.089,0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1288"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1296">
              <g id="g1298" />
              <g id="g1310">
                <g clipPath="url(#clipPath1302)" opacity="0.399994" id="g1308">
                  <g transform="translate(415.2124,152.1973)" id="g1306">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.693,0.198 1.092,0.921 0.894,1.612 l -6.018,20.978 c -0.198,0.692 -0.92,1.09 -1.613,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.089,0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1304"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1312">
              <g id="g1314" />
              <g id="g1326">
                <g clipPath="url(#clipPath1318)" opacity="0.399994" id="g1324">
                  <g transform="translate(407.5767,152.1973)" id="g1322">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.092,0.921 0.894,1.612 l -6.018,20.978 c -0.198,0.692 -0.92,1.09 -1.613,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.089,0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1320"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1328">
              <g id="g1330" />
              <g id="g1342">
                <g clipPath="url(#clipPath1334)" opacity="0.399994" id="g1340">
                  <g transform="translate(399.9409,152.1973)" id="g1338">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.092,0.921 0.894,1.612 l -6.018,20.978 c -0.198,0.692 -0.92,1.09 -1.613,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.089,0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1336"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1344">
              <g id="g1346" />
              <g id="g1358">
                <g clipPath="url(#clipPath1350)" opacity="0.399994" id="g1356">
                  <g transform="translate(392.3052,152.1973)" id="g1354">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.093,0.921 0.894,1.612 l -6.018,20.978 c -0.199,0.692 -0.921,1.09 -1.613,0.894 C -7.07,23.337 -7.47,22.613 -7.271,21.922 L -1.253,0.944 C -1.089,0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1352"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1360">
              <g id="g1362" />
              <g id="g1374">
                <g clipPath="url(#clipPath1366)" opacity="0.399994" id="g1372">
                  <g transform="translate(430.4849,119.8301)" id="g1370">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.092,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.198,-0.691 -0.921,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.018,20.978 C -1.089,-0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1368"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1376">
              <g id="g1378" />
              <g id="g1390">
                <g clipPath="url(#clipPath1382)" opacity="0.399994" id="g1388">
                  <g transform="translate(422.8491,119.8301)" id="g1386">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.093,-0.921 0.894,-1.612 l -6.019,-20.979 c -0.199,-0.691 -0.921,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.893,1.612 l 6.018,20.978 C -1.089,-0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1384"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1392">
              <g id="g1394" />
              <g id="g1406">
                <g clipPath="url(#clipPath1398)" opacity="0.399994" id="g1404">
                  <g transform="translate(446.748,152.1973)" id="g1402">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.092,0.921 0.894,1.612 l -6.018,20.978 c -0.198,0.692 -0.921,1.09 -1.613,0.894 -0.692,-0.198 -1.092,-0.922 -0.894,-1.613 L -1.253,0.944 C -1.089,0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1400"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1408">
              <g id="g1410" />
              <g id="g1422">
                <g clipPath="url(#clipPath1414)" opacity="0.399994" id="g1420">
                  <g transform="translate(439.1123,152.1973)" id="g1418">
                    <path
                      d="m 0,0 c 0.119,0 0.24,0.017 0.36,0.051 0.692,0.198 1.093,0.921 0.894,1.612 l -6.019,20.978 c -0.199,0.692 -0.921,1.09 -1.613,0.894 C -7.07,23.337 -7.47,22.613 -7.271,21.922 L -1.253,0.944 C -1.089,0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1416"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1424">
              <g id="g1426" />
              <g id="g1438">
                <g clipPath="url(#clipPath1430)" opacity="0.399994" id="g1436">
                  <g transform="translate(446.748,119.8301)" id="g1434">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.092,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.198,-0.691 -0.921,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.018,20.978 C -1.089,-0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1432"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1440">
              <g id="g1442" />
              <g id="g1454">
                <g clipPath="url(#clipPath1446)" opacity="0.399994" id="g1452">
                  <g transform="translate(439.1123,119.8301)" id="g1450">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.093,-0.921 0.894,-1.612 l -6.019,-20.979 c -0.199,-0.691 -0.921,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.893,1.612 l 6.018,20.978 C -1.089,-0.372 -0.567,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1448"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1456">
              <g id="g1458" />
              <g id="g1470">
                <g clipPath="url(#clipPath1462)" opacity="0.399994" id="g1468">
                  <g transform="translate(415.2124,119.8301)" id="g1466">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.693,-0.198 1.092,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.198,-0.691 -0.92,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.018,20.978 C -1.089,-0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1464"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1472">
              <g id="g1474" />
              <g id="g1486">
                <g clipPath="url(#clipPath1478)" opacity="0.399994" id="g1484">
                  <g transform="translate(407.5767,119.8301)" id="g1482">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.092,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.198,-0.691 -0.92,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.018,20.978 C -1.089,-0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1480"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1488">
              <g id="g1490" />
              <g id="g1502">
                <g clipPath="url(#clipPath1494)" opacity="0.399994" id="g1500">
                  <g transform="translate(399.9409,119.8301)" id="g1498">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.092,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.198,-0.691 -0.92,-1.089 -1.613,-0.893 -0.692,0.198 -1.092,0.921 -0.894,1.612 l 6.018,20.978 C -1.089,-0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1496"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1504">
              <g id="g1506" />
              <g id="g1518">
                <g clipPath="url(#clipPath1510)" opacity="0.399994" id="g1516">
                  <g transform="translate(392.3052,119.8301)" id="g1514">
                    <path
                      d="m 0,0 c 0.119,0 0.24,-0.017 0.36,-0.051 0.692,-0.198 1.093,-0.921 0.894,-1.612 l -6.018,-20.979 c -0.199,-0.691 -0.921,-1.089 -1.613,-0.893 -0.693,0.198 -1.093,0.921 -0.894,1.612 l 6.018,20.978 C -1.089,-0.372 -0.566,0 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1512"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1520" transform="translate(277.6504,142.2266)">
              <path
                d="m 0,0 h 6.9 l 7.574,26.926 c 0.72,2.557 3.052,4.324 5.71,4.324 H 43.28 c 2.657,0 4.989,-1.766 5.709,-4.323 L 56.572,0 h 126.704 c 1.995,0 3.612,-1.616 3.612,-3.611 v -5.203 c 0,-1.996 -1.617,-3.612 -3.612,-3.612 H 56.572 l -7.583,-26.928 c -0.72,-2.556 -3.052,-4.323 -5.709,-4.323 H 20.181 c -2.656,0 -4.988,1.766 -5.708,4.323 L 6.881,-12.426 H 0 c -1.996,0 -3.612,1.616 -3.612,3.612 v 5.203 C -3.612,-1.616 -1.996,0 0,0"
                style={{
                  fill: '#3BB273',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
                id="path1522"
              />
            </g>
            <g id="g1524">
              <g id="g1526" />
              <g id="g1538">
                <g clipPath="url(#clipPath1530)" opacity="0.199997" id="g1536">
                  <g transform="translate(274.0386,138.6152)" id="g1534">
                    <path
                      d="M 0,0 V -5.203 C 0,-7.198 1.616,-8.814 3.612,-8.814 H 4.186 L 14.76,-44.386 c 1.174,-3.925 4.785,-6.624 8.88,-6.624 h 23.419 c 4.094,0 7.706,2.699 8.866,6.624 L 66.5,-8.814 h 0.574 c 1.995,0 3.612,1.616 3.612,3.611 V 0 c 0,1.995 -1.617,3.611 -3.612,3.611 H 66.5 L 55.925,39.183 c -1.16,3.924 -4.772,6.624 -8.866,6.624 H 23.64 c -4.095,0 -7.706,-2.7 -8.88,-6.624 L 4.186,3.611 H 3.612 C 1.616,3.611 0,1.995 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1532"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1540">
              <g id="g1542" />
              <g id="g1554">
                <g clipPath="url(#clipPath1546)" opacity="0.399994" id="g1552">
                  <g transform="translate(277.6504,142.2266)" id="g1550">
                    <path
                      d="m 0,0 h 6.9 l 7.574,26.926 c 0.72,2.557 3.052,4.324 5.71,4.324 H 43.28 c 2.657,0 4.989,-1.766 5.709,-4.323 L 56.572,0 h 126.704 c 1.995,0 3.612,-1.616 3.612,-3.611 v -5.203 c 0,-1.996 -1.617,-3.612 -3.612,-3.612 H 56.572 l -7.583,-26.928 c -0.72,-2.556 -3.052,-4.323 -5.709,-4.323 H 20.181 c -2.656,0 -4.988,1.766 -5.708,4.323 L 6.881,-12.426 H 0 c -1.996,0 -3.612,1.616 -3.612,3.612 v 5.203 C -3.612,-1.616 -1.996,0 0,0"
                      style={{
                        fill: '#ffffff',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1548"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1556">
              <g id="g1558" />
              <g id="g1570">
                <g clipPath="url(#clipPath1562)" opacity="0.399994" id="g1568">
                  <g transform="translate(295.7002,137.3516)" id="g1566">
                    <path
                      d="m 0,0 c 0,-0.72 0.257,-1.304 0.574,-1.304 h 25.678 c 0.318,0 0.575,0.584 0.575,1.304 0,0.721 -0.257,1.304 -0.575,1.304 H 0.574 C 0.257,1.304 0,0.721 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1564"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1572">
              <g id="g1574" />
              <g id="g1586">
                <g clipPath="url(#clipPath1578)" opacity="0.399994" id="g1584">
                  <g transform="translate(295.7002,143.499)" id="g1582">
                    <path
                      d="m 0,0 c 0,-0.72 0.257,-1.304 0.574,-1.304 h 25.678 c 0.318,0 0.575,0.584 0.575,1.304 0,0.721 -0.257,1.305 -0.575,1.305 H 0.574 C 0.257,1.305 0,0.721 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1580"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1588">
              <g id="g1590" />
              <g id="g1602">
                <g clipPath="url(#clipPath1594)" opacity="0.399994" id="g1600">
                  <g transform="translate(295.7002,149.6475)" id="g1598">
                    <path
                      d="m 0,0 c 0,-0.72 0.257,-1.304 0.574,-1.304 h 25.678 c 0.318,0 0.575,0.584 0.575,1.304 0,0.72 -0.257,1.304 -0.575,1.304 H 0.574 C 0.257,1.304 0,0.72 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1596"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1604">
              <g id="g1606" />
              <g id="g1618">
                <g clipPath="url(#clipPath1610)" opacity="0.399994" id="g1616">
                  <g transform="translate(295.7002,155.7949)" id="g1614">
                    <path
                      d="m 0,0 c 0,-0.72 0.257,-1.304 0.574,-1.304 h 25.678 c 0.318,0 0.575,0.584 0.575,1.304 0,0.721 -0.257,1.304 -0.575,1.304 H 0.574 C 0.257,1.304 0,0.721 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1612"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1620">
              <g id="g1622" />
              <g id="g1634">
                <g clipPath="url(#clipPath1626)" opacity="0.399994" id="g1632">
                  <g transform="translate(295.7002,161.9424)" id="g1630">
                    <path
                      d="m 0,0 c 0,-0.72 0.257,-1.304 0.574,-1.304 h 25.678 c 0.318,0 0.575,0.584 0.575,1.304 0,0.72 -0.257,1.304 -0.575,1.304 H 0.574 C 0.257,1.304 0,0.72 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1628"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1636">
              <g id="g1638" />
              <g id="g1650">
                <g clipPath="url(#clipPath1642)" opacity="0.399994" id="g1648">
                  <g transform="translate(295.7002,106.2285)" id="g1646">
                    <path
                      d="m 0,0 c 0,-0.721 0.257,-1.305 0.574,-1.305 h 25.678 c 0.318,0 0.575,0.584 0.575,1.305 0,0.72 -0.257,1.304 -0.575,1.304 H 0.574 C 0.257,1.304 0,0.72 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1644"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1652">
              <g id="g1654" />
              <g id="g1666">
                <g clipPath="url(#clipPath1658)" opacity="0.399994" id="g1664">
                  <g transform="translate(295.7002,112.376)" id="g1662">
                    <path
                      d="m 0,0 c 0,-0.721 0.257,-1.304 0.574,-1.304 h 25.678 c 0.318,0 0.575,0.583 0.575,1.304 0,0.72 -0.257,1.304 -0.575,1.304 H 0.574 C 0.257,1.304 0,0.72 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1660"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1668">
              <g id="g1670" />
              <g id="g1682">
                <g clipPath="url(#clipPath1674)" opacity="0.399994" id="g1680">
                  <g transform="translate(295.7002,118.5234)" id="g1678">
                    <path
                      d="m 0,0 c 0,-0.72 0.257,-1.304 0.574,-1.304 h 25.678 c 0.318,0 0.575,0.584 0.575,1.304 0,0.721 -0.257,1.305 -0.575,1.305 H 0.574 C 0.257,1.305 0,0.721 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1676"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1684">
              <g id="g1686" />
              <g id="g1698">
                <g clipPath="url(#clipPath1690)" opacity="0.399994" id="g1696">
                  <g transform="translate(295.7002,124.6719)" id="g1694">
                    <path
                      d="m 0,0 c 0,-0.721 0.257,-1.305 0.574,-1.305 h 25.678 c 0.318,0 0.575,0.584 0.575,1.305 0,0.72 -0.257,1.304 -0.575,1.304 H 0.574 C 0.257,1.304 0,0.72 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1692"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1700">
              <g id="g1702" />
              <g id="g1714">
                <g clipPath="url(#clipPath1706)" opacity="0.399994" id="g1712">
                  <g transform="translate(295.7002,130.8184)" id="g1710">
                    <path
                      d="m 0,0 c 0,-0.72 0.257,-1.304 0.574,-1.304 h 25.678 c 0.318,0 0.575,0.584 0.575,1.304 0,0.721 -0.257,1.305 -0.575,1.305 H 0.574 C 0.257,1.305 0,0.721 0,0"
                      style={{
                        fill: '#000000',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                      }}
                      id="path1708"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IllustrationVeterinary

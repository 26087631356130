/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import domainProduction from '@veterical/shared/domainProduction.js'
import facebookHandle from '@veterical/shared/facebookHandle.js'
import linkedinHandle from '@veterical/shared/linkedinHandle.js'
import twitterHandle from '@veterical/shared/twitterHandle.js'
import youtubeHandle from '@veterical/shared/youtubeHandle.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import colLg3 from '@stiloso/styles/bootstrap/colLg3.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import IconFacebook from '@stiloso/icons/IconFacebook.js'
import IconLinkedin from '@stiloso/icons/IconLinkedin.js'
import IconTwitter from '@stiloso/icons/IconTwitter.js'
import IconYoutube from '@stiloso/icons/IconYoutube.js'
import { useTranslation } from '@multilocale/react/index.js'

const footerSection = css`
  color: white;
  padding-top: 32px;
  background-color: var(--color-primary);
`

const footerTitle = css`
  font-size: 24px;
  text-transform: capitalize;
  line-height: 48px;
`

const footerBox = css`
  display: flex;
  flex-direction: column;

  a {
    width: max-content;
  }
`

const footerText = css`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`

const footerLink = css`
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;

  @media (hover: hover) {
    &:not(:disabled):hover {
      color: var(--color-secondary-light);
    }
  }

  &:not(:disabled):active {
    color: var(--color-secondary-dark);
  }
`

const footerSocialContainer = css`
  margin-left: -6px;
  margin-right: 16px;
`

const footerSocialIcon = css`
  fill: white;
  width: 38px;
  height: 38px;

  @media (hover: hover) {
    &:not(:disabled):hover {
      fill: var(--color-secondary-light);
    }
  }

  &:not(:disabled):active {
    fill: var(--color-secondary-dark);
  }
`

const footerCopyrightContainer = css`
  background-color: var(--color-primary-dark);
  margin-top: 32px;
  padding: 16px;
  width: 100%;
  text-align: center;
`

const footerCopyrightText = css`
  ${footerText}
  color: white;
  margin: 0px;
`
const footerCopyrightPolyblogLink = css`
  @media (hover: hover) {
    &:not(:disabled):hover {
      color: var(--color-secondary-light);
    }
  }

  &:not(:disabled):active {
    color: var(--color-secondary-dark);
  }
`

const FooterComponent = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="footer-section" css={[footerSection]}>
      <div css={[container]}>
        <div css={[row]}>
          <div css={[colSm12, colMd6, colLg3, { marginTop: 32 }]}>
            <div css={footerBox}>
              <h3 css={footerTitle}>{t('Language')}</h3>
              <a href="/en/" css={footerLink}>
                English
              </a>
              <a href="/es/" css={footerLink}>
                Español
              </a>
              <a href="/de/" css={footerLink}>
                Deutsch
              </a>
              <a href="/pt/" css={footerLink}>
                Português
              </a>
              <a href="/fr/" css={footerLink}>
                Français
              </a>
              <a href="/it/" css={footerLink}>
                Italiano
              </a>
              <a href="/th/" css={footerLink}>
                ไทย
              </a>
              <a href="/nl/" css={footerLink}>
                Nederlands
              </a>
              <a href="/el/" css={footerLink}>
                ελληνικά
              </a>
              <a href="/id/" css={footerLink}>
                Bahasa Indonesia
              </a>
              <a href="/pl/" css={footerLink}>
                Polski
              </a>
              <a href="/tr/" css={footerLink}>
                Türkçe
              </a>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg3, { marginTop: 32 }]}>
            <div css={footerBox}>
              <h3 css={footerTitle}>{t('Resources')}</h3>
              <a href={`/blog/${locale}/`} css={footerLink}>
                {t('Blog')}
              </a>
              <a href={`/docs/${locale}/`} css={footerLink}>
                {t('Docs')}
              </a>
              <a href={`/${locale}/pricing/`} css={footerLink}>
                {t('Pricing')}
              </a>
              <a href={`/${locale}/privacy-policy/`} css={footerLink}>
                {t('Privacy Policy')}
              </a>
            </div>
          </div>
          {(facebookHandle || linkedinHandle || twitterHandle) && (
            <div css={[colSm12, colMd6, colLg3, { marginTop: 32 }]}>
              <div css={footerBox}>
                <h3 css={footerTitle}>{t('Follow Us')}</h3>
                <div css={{ display: 'flex' }}>
                  {linkedinHandle && (
                    <div css={footerSocialContainer}>
                      <a
                        href={`https://www.linkedin.com/company/${linkedinHandle}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Linkedin"
                        css={footerLink}
                      >
                        <IconLinkedin css={footerSocialIcon} />
                      </a>
                    </div>
                  )}
                  {twitterHandle && (
                    <div css={footerSocialContainer}>
                      <a
                        href={`https://www.twitter.com/${twitterHandle}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Twitter"
                        css={footerLink}
                      >
                        <IconTwitter
                          css={[footerSocialIcon, { width: 32, height: 32 }]}
                        />
                      </a>
                    </div>
                  )}
                  {facebookHandle && (
                    <div css={footerSocialContainer}>
                      <a
                        href={`https://www.facebook.com/${facebookHandle}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Facebook"
                        css={footerLink}
                      >
                        <IconFacebook css={footerSocialIcon} />
                      </a>
                    </div>
                  )}
                  {youtubeHandle && (
                    <div css={footerSocialContainer}>
                      <a
                        href={`https://www.youtube.com/@${youtubeHandle}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Youtube"
                        css={footerLink}
                      >
                        <IconYoutube
                          css={[
                            footerSocialIcon,
                            { width: 36, height: 36, marginTop: '-2' },
                          ]}
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div css={[colSm12, colMd6, colLg3, { marginTop: 32 }]}>
            <div css={footerBox}>
              <h3 css={footerTitle}>{t('Contact us')}</h3>
              <a
                href={`mailto:info@${domainProduction}`}
                target="_blank"
                rel="noopener noreferrer"
                css={footerLink}
              >
                {`info@${domainProduction}`}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div css={footerCopyrightContainer}>
        <p css={footerCopyrightText}>
          {t('Copyright')} @{' '}
          <Link to={`/${locale}/`} css={footerCopyrightPolyblogLink}>
            Veterical
          </Link>{' '}
          2023 - {new Date().getFullYear()}
        </p>
      </div>
    </section>
  )
}

export default FooterComponent

/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  en: 'English',
  es: 'Español',
  de: 'Deutsch',
  pt: 'Português',
  it: 'Italiano',
  fr: 'Français',
  ru: 'Русский',
  nl: 'Nederlands',
  th: 'ไทย',
  el: 'ελληνικά',
  zh: '中文',
  id: 'Bahasa Indonesia',
  tr: 'Türkçe',
  pl: 'Polski',
  ko: '한국어',
  hu: 'Magyar',
  ro: 'Română',
  hr: 'Hrvatski jezik',
  vi: 'Tiếng Việt',
  sr: 'Српски',
  ar: 'العربية',
  da: 'Dansk',
  sv: 'Svenska',
  ca: 'Català',
  uk: 'Українська',
  ja: '日本語',
  cs: 'Čeština',
}

/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dLgNone from '@stiloso/styles/bootstrap/dLgNone.js'
import NavigationDesktopComponent from './NavigationDesktopComponent.js'
import NavigationSmartphoneComponent from './NavigationSmartphoneComponent.js'

const NavigationComponent = props => (
  <>
    <div css={dLgNone}>
      <NavigationSmartphoneComponent {...props} />
    </div>
    <div css={[dNone, dLgBlock]}>
      <NavigationDesktopComponent {...props} />
    </div>
  </>
)

export default NavigationComponent

/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import getAppUrl from '@veterical/shared/getAppUrl.js'
import androidPackageName from '@veterical/shared/androidPackageName.js'
import iOSAppStoreId from '@veterical/shared/iOSAppStoreId.js'
import macOSAppStoreId from '@veterical/shared/macOSAppStoreId.js'
import windowsProductId from '@veterical/shared/windowsProductId.js'
import DownloadsSectionStiloso from '@stiloso/sections/DownloadsSection.js'

const DownloadsSection = ({ locale }) => {
  let macos =
    macOSAppStoreId && `https://apps.apple.com/app/id${macOSAppStoreId}`
  let ios = iOSAppStoreId && `https://apps.apple.com/app/id${iOSAppStoreId}`
  let android =
    androidPackageName &&
    `https://play.google.com/store/apps/details?id=${androidPackageName}`
  let webapp = getAppUrl()
  let windows =
    windowsProductId && `https://apps.microsoft.com/detail/${windowsProductId}`

  return (
    <DownloadsSectionStiloso
      android={android}
      ios={ios}
      locale={locale}
      macos={macos}
      webapp={webapp}
      windows={windows}
    />
  )
}

export default DownloadsSection

/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
import { useSearchParams } from 'react-router-dom'
import setCookie from '@monorepo/shared/setCookie.js'

const UtmCampaignCookie = () => {
  const [searchParams] = useSearchParams()
  const utm_campaign = searchParams.get('utm_campaign')

  if (utm_campaign) {
    setCookie('utm_campaign', utm_campaign)
  }
}

export default UtmCampaignCookie

/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconCloudSearch = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-cloud-search'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 499.974 404.909"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path
        // eslint-disable-next-line max-len
        d="M499.974 215.262c0-52.926-42.896-95.811-95.821-95.811-4.323 0-8.517.387-12.679.945C387.826 53.268 332.262 0 264.267 0c-55.521 0-97.794 40.418-115.268 89.932-9.322-2.445-24.022-8.646-34.1-8.646C51.439 81.286 0 132.711 0 196.191c0 63.436 51.439 114.881 114.899 114.881h83.142c18.712 17.464 43.737 28.233 71.279 28.233 17.248 0 33.477-4.249 47.83-11.671 1.79 3.561 3.989 6.886 6.886 9.782l6.018 6.028a18.926 18.926 0 0 0 2.832 2.253 15.738 15.738 0 0 0 2.263 2.854l45.395 45.395c7.08 7.057 16.466 10.962 26.485 10.962 9.997 0 19.404-3.905 26.452-10.941 7.068-7.079 10.973-16.476 10.973-26.495 0-9.997-3.905-19.393-10.962-26.452l-29.938-29.949h.601c52.923.001 95.819-42.884 95.819-95.809zM269.319 322.143a86.915 86.915 0 0 1-42.537-11.071c-26.8-14.953-44.945-43.55-44.961-76.416.016-48.312 39.175-87.486 87.498-87.486 48.312 0 87.475 39.174 87.486 87.486-.011 14.095-3.422 27.374-9.343 39.174a87.625 87.625 0 0 1-34.346 36.47c-.429.258-.837.537-1.266.772a86.871 86.871 0 0 1-42.531 11.071zm60.938-11.071c-.021-5.213 1.942-10.447 5.922-14.437 5.61-5.6 13.654-7.144 20.692-4.806a20.018 20.018 0 0 1 6.822 3.883c.365.322.772.579 1.115.923l6.018 6.028-8.4 8.409-20.208 20.23-6.039-6.049c-.354-.344-.601-.729-.912-1.116-1.501-1.717-2.746-3.626-3.561-5.643a20.562 20.562 0 0 1-1.449-7.422zm91.069 42.113c7.916 7.895 7.916 20.703 0 28.597-7.894 7.916-20.713 7.916-28.618 0l-38.358-38.337-7.047-7.058 25.336-25.315 3.304-3.303 3.304 3.303 3.732 3.733 38.347 38.38z"
      />
      <path d="M311.453 276.79a58.983 58.983 0 0 0 3.304-3.647c8.796-10.383 14.127-23.77 14.138-38.487-.011-16.454-6.672-31.322-17.442-42.134-10.823-10.77-25.68-17.442-42.145-17.442-16.465 0-31.322 6.672-42.124 17.442-10.796 10.812-17.451 25.68-17.451 42.134 0 16.476 6.655 31.343 17.451 42.134 10.802 10.77 25.659 17.464 42.124 17.464 16.037 0 30.517-6.35 41.233-16.647.311-.28.622-.538.912-.817zm-42.327-76.588c-2.543-1.073-3.754-3.991-2.649-6.543 1.062-2.553 3.99-3.734 6.554-2.66 19.265 8.152 33.874 25.122 38.776 45.888a4.979 4.979 0 0 1-3.721 6.007c-.387.108-.751.15-1.138.15a5.006 5.006 0 0 1-4.869-3.862c-4.142-17.613-16.596-32.05-32.953-38.98zm34.668 53.246a4.988 4.988 0 0 1 4.988-4.998 4.992 4.992 0 0 1 5.01 4.998 4.992 4.992 0 0 1-5.01 4.999 4.988 4.988 0 0 1-4.988-4.999z" />
    </svg>
  )
}

export default IconCloudSearch

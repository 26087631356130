/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IllustrationInformation = props => {
  let {
    className,
    width,
    height,
    onClick,
    style,
    primaryColor = 'var(--color-primary)',
    secondaryColor = 'var(--color-secondary)',
  } = props
  const dataTestId =
    props['data-testid'] || 'illustration-man-working-on-computer'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 897.31838 556.97524"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path
        fill={secondaryColor}
        d="m217.339 502.047.998-22.434a72.456 72.456 0 0 1 33.795-8.555c-16.231 13.27-14.203 38.85-25.207 56.696a43.582 43.582 0 0 1-31.96 20.14l-13.583 8.317a73.03 73.03 0 0 1 15.393-59.18 70.542 70.542 0 0 1 12.965-12.045c3.253 8.578 7.599 17.06 7.599 17.06Z"
      />
      <path
        fill="#cacaca"
        d="M796.921 36.552H164.598a1.016 1.016 0 0 1 0-2.03h632.323a1.016 1.016 0 0 1 0 2.03Z"
      />
      <ellipse
        cx="186.953"
        cy="11.169"
        fill="#3f3d56"
        rx="10.925"
        ry="11.169"
      />
      <ellipse
        cx="224.695"
        cy="11.169"
        fill="#3f3d56"
        rx="10.925"
        ry="11.169"
      />
      <ellipse
        cx="262.437"
        cy="11.169"
        fill="#3f3d56"
        rx="10.925"
        ry="11.169"
      />
      <path
        fill="#3f3d56"
        d="M774.304 2.768h-26.81a2.03 2.03 0 0 0 0 4.06h26.81a2.03 2.03 0 0 0 0-4.06ZM774.304 10.388h-26.81a2.03 2.03 0 0 0 0 4.06h26.81a2.03 2.03 0 0 0 0-4.06ZM774.304 17.998h-26.81a2.03 2.03 0 0 0 0 4.06h26.81a2.03 2.03 0 0 0 0-4.06ZM656.713 116.141h-434.01a8.07 8.07 0 0 0-8.07 8.06v204.87a8.079 8.079 0 0 0 8.07 8.07h434.01a8.077 8.077 0 0 0 8.06-8.07v-204.87a8.068 8.068 0 0 0-8.06-8.06Z"
      />
      <path
        fill={primaryColor}
        d="M542.073 214.842a8.068 8.068 0 0 0-8.06 8.06v57.87a8.077 8.077 0 0 0 8.06 8.07h122.7v-74Z"
      />
      <path
        fill={primaryColor}
        d="M871.088 288.837h-329.01a8.076 8.076 0 0 1-8.067-8.066v-57.868a8.075 8.075 0 0 1 8.067-8.066h329.01a8.075 8.075 0 0 1 8.066 8.066v57.868a8.076 8.076 0 0 1-8.066 8.066Z"
        opacity=".5"
      />
      <circle cx="586.571" cy="255.537" r="13.089" fill="#fff" />
      <path
        fill="#fff"
        d="M860.894 251.734H624.38a3.898 3.898 0 1 1 0-7.796h236.514a3.898 3.898 0 1 1 0 7.796ZM771.063 267.135H624.38a3.898 3.898 0 1 1 0-7.795h146.683a3.898 3.898 0 0 1 0 7.795Z"
      />
      <path
        fill="#ffb6b6"
        d="m151.406 545.537 11.328-.001 5.389-43.693h-16.719l.002 43.694z"
      />
      <path
        fill="#2f2e41"
        d="M148.517 541.838h3.188l12.449-5.062 6.671 5.061h.001a14.218 14.218 0 0 1 14.217 14.217v.462l-36.526.001Z"
      />
      <path
        fill="#ffb6b6"
        d="m49.051 530.809 10.139 5.053 24.314-36.701-14.963-7.458-19.49 39.106z"
      />
      <path
        fill="#2f2e41"
        d="m48.115 526.21 2.854 1.422 13.4 1.022 3.712 7.507h.001a14.218 14.218 0 0 1 6.382 19.066l-.206.413-32.69-16.292ZM156.425 347.096l-72.026 1.88 1.253 35.073s-1.253 9.395 1.252 11.9 3.758 2.505 2.506 6.89-4.491 46.273-4.491 46.273-29.562 52.27-28.31 53.522 2.506 0 1.253 3.132-2.505 1.879-1.252 3.132a46.128 46.128 0 0 1 3.131 3.757h20.416s1.142-6.263 1.142-6.889 1.252-4.384 1.252-5.01 35.67-38.418 35.67-38.418l7.515-62.631 18.163 61.378s0 53.863 1.253 55.116 1.252.626.626 3.132-3.132 1.878-1.253 3.757 2.505-1.252 1.88 1.88l-.627 3.13 24.062.27s2.506-5.28 1.253-7.159-1.178-1.366.35-4.44 2.155-3.702 1.529-4.328-.626-3.958-.626-3.958-9.031-123.183-9.031-125.062a6.247 6.247 0 0 1 .52-2.818v-2.55l-2.4-9.038Z"
      />
      <path
        fill={primaryColor}
        d="M869.68 238.348a27.638 27.638 0 1 1 27.638-27.638 27.638 27.638 0 0 1-27.638 27.638Z"
      />
      <path
        fill="#fff"
        d="M880.586 207.984h-8.18v-8.18a2.726 2.726 0 0 0-5.452 0v8.18h-8.179a2.726 2.726 0 1 0 0 5.452h8.18v8.18a2.726 2.726 0 0 0 5.452 0v-8.18h8.179a2.726 2.726 0 1 0 0-5.452Z"
      />
      <path
        fill={primaryColor}
        d="M447.883 289.212h-105.01a8.079 8.079 0 0 0-8.07 8.07v39.86h121.14v-39.86a8.077 8.077 0 0 0-8.06-8.07Z"
      />
      <path
        fill={primaryColor}
        d="M447.88 401.212H342.87a8.076 8.076 0 0 1-8.067-8.067v-95.867a8.075 8.075 0 0 1 8.067-8.067h105.01a8.075 8.075 0 0 1 8.066 8.067v95.867a8.076 8.076 0 0 1-8.066 8.067Z"
        opacity=".5"
      />
      <circle cx="373.808" cy="321.563" r="13.089" fill="#fff" />
      <path
        fill="#fff"
        d="M426.131 354.547h-61.514a3.898 3.898 0 1 1 0-7.795h61.514a3.898 3.898 0 1 1 0 7.795ZM394.3 369.95h-29.683a3.898 3.898 0 0 1 0-7.797H394.3a3.898 3.898 0 0 1 0 7.796Z"
      />
      <path
        fill={primaryColor}
        d="M340.68 429.348a27.638 27.638 0 1 1 27.638-27.638 27.638 27.638 0 0 1-27.638 27.638Z"
      />
      <path
        fill="#fff"
        d="M351.586 398.984h-8.18v-8.18a2.726 2.726 0 1 0-5.452 0v8.18h-8.179a2.726 2.726 0 1 0 0 5.452h8.18v8.18a2.726 2.726 0 1 0 5.452 0v-8.18h8.179a2.726 2.726 0 1 0 0-5.452Z"
      />
      <path
        fill={primaryColor}
        d="M327.887 228.266h-105.01a8.076 8.076 0 0 1-8.067-8.066v-95.867a8.075 8.075 0 0 1 8.067-8.067h105.01a8.075 8.075 0 0 1 8.066 8.067V220.2a8.076 8.076 0 0 1-8.066 8.066Z"
      />
      <circle cx="253.816" cy="156.618" r="13.089" fill={primaryColor} />
      <path
        fill={primaryColor}
        d="M306.139 185.602h-61.514a3.898 3.898 0 1 1 0-7.795h61.514a3.898 3.898 0 1 1 0 7.795ZM274.308 201.004h-29.683a3.898 3.898 0 1 1 0-7.796h29.683a3.898 3.898 0 1 1 0 7.796Z"
      />
      <path
        fill={primaryColor}
        d="M327.887 228.266h-105.01a8.076 8.076 0 0 1-8.067-8.066v-95.867a8.075 8.075 0 0 1 8.067-8.067h105.01a8.075 8.075 0 0 1 8.066 8.067V220.2a8.076 8.076 0 0 1-8.066 8.066Z"
        opacity=".5"
      />
      <circle cx="253.816" cy="156.618" r="13.089" fill="#fff" />
      <path
        fill="#fff"
        d="M306.139 185.602h-61.514a3.898 3.898 0 1 1 0-7.795h61.514a3.898 3.898 0 1 1 0 7.795ZM274.308 201.004h-29.683a3.898 3.898 0 1 1 0-7.796h29.683a3.898 3.898 0 1 1 0 7.796Z"
      />
      <circle cx="225.043" cy="115.951" r="21" fill={secondaryColor} />
      <path
        fill="#ccc"
        d="M282.67 555.785a1.186 1.186 0 0 1-1.19 1.19H1.19a1.19 1.19 0 0 1 0-2.38h280.29a1.187 1.187 0 0 1 1.19 1.19Z"
      />
      <path
        fill="#ffb6b6"
        d="M220.555 171.576a9.77 9.77 0 0 1-5.759 12.435 9.6 9.6 0 0 1-1.635.451l-5.547 33.96-13.01-12.013 7.262-30.407a9.806 9.806 0 0 1 8.59-10.76 9.547 9.547 0 0 1 10.099 6.334Z"
      />
      <path
        fill="#3f3d56"
        d="M124.54 248.524s10.098-13.341 46.74-12.976l20.797-7.556 4.753-43.57 16.636 3.96-2.377 53.87-35.648 20.596-46.739 9.506Z"
      />
      <circle
        cx="119.175"
        cy="198.983"
        r="21.747"
        fill="#ffb6b6"
        data-name="ab6171fa-7d69-4734-b81c-8dff60f9761b"
      />
      <path
        fill="#3f3d56"
        d="M82.367 363.878a.417.417 0 0 1-.114-.016c-.401-.112-.719-.2.73-12.73l1.564-9.903-1.526-8.744-2.568-2.568 4.127-4.127 3.463-9.838-5.993-8.88-6.875-36.317a28.972 28.972 0 0 1 15.91-31.478l7.958-2.325 2.896-5.31a9.52 9.52 0 0 1 8.286-4.962l14.573-.11a9.52 9.52 0 0 1 7.617 3.716l5.084 6.609 21.082 7.161-3.495 75.322a5.233 5.233 0 0 1 .359 7.695c-.22.221-.393.401-.5.52-.356.505.31 4.275 1.134 7.475l1.056 4.902a3.013 3.013 0 0 0-.548 4.398l1.347 1.59a7.6 7.6 0 0 1-6.508 8.536c-19.267 2.622-68.958 9.384-69.059 9.384Z"
      />
      <path
        fill="#2f2e41"
        d="M113.612 219.665q-.14-.307-.278-.615c.036 0 .07.006.106.007ZM96.823 178.224a6.053 6.053 0 0 1 3.792-1.64c1.406.046 2.832 1.316 2.54 2.693a22.348 22.348 0 0 1 26.896-10.085c3.495 1.233 6.922 3.7 7.725 7.318a6.591 6.591 0 0 0 .83 2.702 3.084 3.084 0 0 0 3.283.832l.034-.01a1.028 1.028 0 0 1 1.242 1.45l-.989 1.844a7.925 7.925 0 0 0 3.776-.08 1.027 1.027 0 0 1 1.09 1.598 17.895 17.895 0 0 1-14.269 7.334c-3.951-.024-7.943-1.386-11.789-.477a10.24 10.24 0 0 0-6.887 14.375c-1.182-1.292-3.466-.986-4.674.28a6.41 6.41 0 0 0-1.4 4.906 22.757 22.757 0 0 0 2.337 7.638 22.836 22.836 0 0 1-13.537-40.678Z"
      />
      <path
        fill="#ffb6b6"
        d="M90.84 395.068a9.77 9.77 0 0 1-2.303-13.509 9.6 9.6 0 0 1 1.092-1.298l-14.675-31.123 17.527 2.525 11.249 29.167a9.806 9.806 0 0 1-.98 13.733 9.547 9.547 0 0 1-11.91.505Z"
      />
      <path
        fill="#3f3d56"
        d="m86.395 378.074-23.352-52.483-.234-41.452 7.361-22.39a23.925 23.925 0 0 1 30.828-15.04l.162.058.068.158c.272.635 6.446 15.907-11.867 47.323l-3.686 21.496 12.933 49.274Z"
      />
    </svg>
  )
}

export default IllustrationInformation

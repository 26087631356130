/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import unselectable from '@stiloso/styles/unselectable.js'

const button = css`
  ${unselectable};
  background-color: var(--color-secondary);
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  margin: 10px 0 10px 16px;
  padding: 0px 36px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  :hover,
  :focus-visible {
    background-color: var(--color-secondary-dark);
  }

  :active {
    background: var(--color-secondary-darker);
  }

  @media (max-width: 1199px) {
    padding: 0 24px;
  }
  @media (max-width: 992px) {
    padding: 0 16px;
    line-height: 40px;
    margin: 0;
    display: flex;
    flex-grow: 0.25;
    justify-content: center;
  }
  @media (max-width: 479px) {
    padding: 0 8px;
    line-height: 32px;
    font-size: 14px;
  }
`

const NavBarButton = ({ label, to, href }) => {
  if (href) {
    return (
      <a css={button} href={href}>
        {label}
      </a>
    )
  } else {
    return (
      <Link css={button} to={to}>
        {label}
      </Link>
    )
  }
}

export default NavBarButton

/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'

const iconDefault = css`
  fill: none;
  stroke: white;
  stroke-width: 2;
  transition: stroke 0.2s;
  cursor: pointer;

  :hover {
    stroke: #ffa503;
  }
`

const logoDark = css`
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-size: 20px;
    letter-spacing: 2px;
    color: var(--color-base);
    margin-left: 8px;
    transition: color 0.125s ease;
    user-select: none;
  }

  svg {
    stroke: var(--color-base);
  }

  .paw {
    fill: var(--color-primary);
  }

  @media (hover: hover) {
    :hover {
      svg {
        stroke: var(--color-secondary);
      }
      span {
        color: var(--color-secondary);
      }
      .paw {
        fill: var(--color-secondary);
      }
    }
  }

  :active {
    svg {
      stroke: var(--color-secondary-dark);
    }
    span {
      color: var(--color-secondary-dark);
    }
    .paw {
      fill: var(--color-secondary-dark);
    }
  }
`

const logoLight = css`
  ${logoDark}
  span {
    color: white;
  }
  svg {
    stroke: white;
  }
  .paw {
    fill: #ffffff;
  }
`

const IconVeterical = props => {
  const { width = 32, height = 32, light, onClick } = props
  const dataTestId = props['data-testid'] || 'icon-globo'

  return (
    <span css={light ? logoLight : logoDark}>
      <svg
        css={iconDefault}
        width={width}
        height={height}
        onClick={onClick}
        data-testid={dataTestId}
        viewBox="0 -960 960 960"
      >
        <path
          className="paw"
          d="M180-475q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm180-160q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm240 0q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm180 160q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM266-75q-45 0-75.5-34.5T160-191q0-52 35.5-91t70.5-77q29-31 50-67.5t50-68.5q22-26 51-43t63-17q34 0 63 16t51 42q28 32 49.5 69t50.5 69q35 38 70.5 77t35.5 91q0 47-30.5 81.5T694-75q-54 0-107-9t-107-9q-54 0-107 9t-107 9Z"
        />
      </svg>
      <span>Veterical</span>
    </span>
  )
}

export default IconVeterical

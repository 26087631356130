/* Copyright 2013 - 2024 Waiterio LLC */
import { useSearchParams } from 'react-router-dom'
import setCookie from '@monorepo/shared/setCookie.js'

const GclidCookie = () => {
  const [searchParams] = useSearchParams()
  const gclid = searchParams.get('gclid')

  if (gclid) {
    setCookie('gclid', gclid)
  }
}

export default GclidCookie

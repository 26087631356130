/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import colLg4 from '@stiloso/styles/bootstrap/colLg4.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'

const section = css`
  padding: 80px 0px;
  background-color: white;

  @media (max-width: 992px) {
    padding: 64px 0px;
  }

  @media (max-width: 768px) {
    padding: 48px 0px;
  }
`

const sectionHeading = css`
  font-size: 36px;
  text-transform: capitalize;
  font-weight: bold;
  color: white;
  text-align: center;
  color: var(--color-primary);

  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }
`

const box = css`
  position: relative;
  margin-top: 48px;
  padding: 30px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
`
const logoStyle = css`
  border-radius: 50%;
  position: absolute;
  left: 50%;
  padding: 16px;
  top: 0;
  transform: translate(-50%, -50%);
`

const contentStyle = css`
  line-height: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
`

const TestimonialSection = ({ title, testimonials }) => (
  <section id="testimonial-section" css={section}>
    <div css={container}>
      <div css={row}>
        <div css={colSm12}>
          <h2 css={sectionHeading}>{title}</h2>
        </div>
      </div>
      <div css={[row, { marginTop: 48 }]}>
        {testimonials.map(({ name, color, logo, content, url }) => (
          <div key={name} css={[colMd6, colLg4]}>
            <div css={box}>
              <div css={[logoStyle, { backgroundColor: color }]}>{logo}</div>
              <p css={contentStyle}>
                <q>{content}</q>
              </p>
              <div css={{ fontWeight: 'bold', textAlign: 'center' }}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  css={{
                    ':hover, :focus-visible': {
                      color: 'var(--color-secondary)',
                    },
                    ':active': { color: 'var(--color-secondary-dark)' },
                  }}
                  target="_blank"
                  href={url}
                >
                  {name}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default TestimonialSection

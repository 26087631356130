/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import GlobalStyles from '@stiloso/components/GlobalStyles.js'
import colorPrimary from './colorPrimary.js'
import colorSecondary from './colorSecondary.js'

const GlobalStyles_ = () => (
  <GlobalStyles colorPrimary={colorPrimary} colorSecondary={colorSecondary} />
)

export default GlobalStyles_

/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useRef, useEffect } from 'react'
import { css, jsx } from '@emotion/react'
import colLg6 from '@stiloso/styles/bootstrap/colLg6.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colLg12 from '@stiloso/styles/bootstrap/colLg12.js'
import colMd12 from '@stiloso/styles/bootstrap/colMd12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import IconCollapse from '@stiloso/icons/IconCollapse.js'
import IconExpand from '@stiloso/icons/IconExpand.js'

const questionsSection = css`
  padding: 80px 0px;
  background-color: white;

  @media (max-width: 992px) {
    padding: 64px 0px;
  }

  @media (max-width: 768px) {
    padding: 48px 0px;
  }
`

const sectionTitle = css`
  font-size: 36px;
  text-transform: capitalize;
  font-weight: bold;
  color: var(--color-primary);
  text-align: center;

  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 32px;
  }
`

const questionTitle = css({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: 18,
  cursor: 'pointer',
  color: 'var(--color-base)',
  backgroundColor: 'white',
  borderWidth: 1,
  borderBottom: 0,
  borderColor: '#D8E0ED',
  borderStyle: 'solid',
  borderRadius: 4,
  padding: 16,
  ':not(:first-of-type)': {
    marginTop: 24,
  },
})

const iconCollapseExpand = css({
  fill: 'var(--color-primary)',
  minWidth: 32,
  minHeight: 32,
  marginLeft: 8,
})

const questionContent = css({
  overflow: 'hidden',
  backgroundColor: 'white',
  border: '1px solid #D8E0ED',
  color: '#6A768A',
  fontSize: 16,
  fontWeight: '400',
  p: {
    lineHeight: '26px',
    margin: 0,
  },
  a: {
    color: 'var(--color-primary)',
    '@media (hover: hover)': {
      ':hover': {
        color: 'var(--color-secondary-light)',
      },
    },
    ':focus-visible': {
      color: 'var(--color-secondary-light)',
    },
    ':active': {
      color: 'var(--color-secondary-dark)',
    },
  },
})

const QuestionsSection = ({ locale, title, documents }) => (
  <section id="questions-section" css={questionsSection}>
    <div css={container}>
      <div css={[row, { justifyContent: 'center' }]}>
        <div css={[colMd6, colLg6]}>
          <h2 css={sectionTitle}>{title}</h2>
        </div>
      </div>
      <div css={[row, { marginTop: 48 }]}>
        <div css={[colMd12, colLg12]}>
          {documents?.map(
            (
              {
                title,
                content,
                // url
              },
              index,
            ) => (
              <QuestionAccordion
                key={title || index}
                title={title}
                content={content}
                locale={locale}
                // url={url}
              />
            ),
          )}
        </div>
      </div>
    </div>
  </section>
)

const QuestionAccordion = ({ title, content }) => {
  const [expanded, setExpanded] = useState(false)
  const [height, setHeight] = useState(0)

  const contentRef = useRef(null)

  const onClick = () => {
    const { scrollHeight } = contentRef.current

    setExpanded(expanded => !expanded)
    setHeight(scrollHeight)
  }

  useEffect(() => {
    function changeHeight() {
      const { scrollHeight } = contentRef.current

      setHeight(scrollHeight)
    }

    window.addEventListener('resize', changeHeight)

    return function cleanup() {
      window.removeEventListener('resize', changeHeight)
    }
  }, [])

  return (
    <>
      <div css={questionTitle} onClick={onClick}>
        <div>{title}</div>
        {expanded ? (
          <IconCollapse css={iconCollapseExpand} />
        ) : (
          <IconExpand css={iconCollapseExpand} />
        )}
      </div>
      <div
        ref={contentRef}
        css={[
          questionContent,
          {
            height: expanded ? height : 0,
            borderTop: expanded ? 0 : 1,
          },
        ]}
      >
        <div
          css={{ padding: '0px 16px 16px' }}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </>
  )
}

export default QuestionsSection

/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import captureExceptionBrowser from '@monorepo/telemetry/captureExceptionBrowser.js'
import ErrorBoundaryWithoutSentry from './ErrorBoundaryWithoutSentry.js'

const ErrorBoundary = ({ children, onReset }) => (
  <ErrorBoundaryWithoutSentry
    onCatchError={captureExceptionBrowser}
    onReset={onReset}
  >
    {children}
  </ErrorBoundaryWithoutSentry>
)

export default ErrorBoundary

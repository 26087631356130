/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable react/jsx-no-target-blank */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import IconWindows from '@stiloso/icons/IconWindows.js'
import IconMac from '@stiloso/icons/IconMac.js'
import IconAndroid from '@stiloso/icons/IconNewAndroid.js'
import IconApple from '@stiloso/icons/IconFabApple.js'
import IconLinux from '@stiloso/icons/IconLinux.js'
import colLg2 from '@stiloso/styles/bootstrap/colLg2.js'
import colSm10 from '@stiloso/styles/bootstrap/colSm10.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'

const section = css`
  background-color: var(--color-background);
  padding-top: 50px;
  padding-bottom: 100px;
  text-align: center;
`

const boxShadow = css`
  background-color: white;
  padding: 60px 0;
`

const title = css`
  color: var(--color-primary);
  font-size: 52px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 25px;
  margin-top: 0px;
`

const subtitle = css`
  color: var(--color-base);
  font-size: 24px;
  line-height: 36px;
  margin: 0px;
  padding: 0 80px;
`

const osLink = css`
  cursor: pointer;
  color: var(--color-primary);
  fill: var(--color-primary);
  display: block;
  padding-top: 48px;
  :hover {
    color: var(--color-secondary);
    fill: var(--color-secondary);
  }
`

const DownloadsSection = ({
  android,
  ios,
  linux,
  locale,
  macos,
  windows,
  webapp,
}) => {
  const { t } = useTranslation(locale)
  android ||= webapp
  ios ||= webapp
  linux ||= webapp
  macos ||= webapp
  windows ||= webapp

  return (
    <section id="downloads" css={section}>
      <div css={{ height: 90, backgroundColor: '#f5f7fb' }} />
      <div css={container}>
        <div css={boxShadow}>
          <div css={[row, { justifyContent: 'center' }]}>
            <div css={colSm10}>
              <h1 css={title}>{t('Downloads')}</h1>
              <p css={subtitle}>{t('__downloads_subtitle__')}</p>
            </div>
          </div>
          <div css={[row, { justifyContent: 'center', marginTop: 30 }]}>
            <a css={[colLg2, osLink]} href={windows} target="_blank">
              <IconWindows
                css={{
                  width: 35,
                  height: 40,
                  marginBottom: 8,
                }}
              />
              <div>Windows</div>
            </a>
            <a css={[colLg2, osLink]} href={macos} target="_blank">
              <IconMac
                css={{
                  width: 45,
                  height: 40,
                  marginBottom: 8,
                }}
              />
              <div>macOS</div>
            </a>
            <a css={[colLg2, osLink]} href={android} target="_blank">
              <IconAndroid
                css={{
                  width: 45,
                  height: 40,
                  marginBottom: 8,
                }}
              />
              <div>Android</div>
            </a>
            <a css={[colLg2, osLink]} href={ios} target="_blank">
              <IconApple
                css={{
                  width: 45,
                  height: 40,
                  marginBottom: 8,
                }}
              />
              <div>iOS</div>
            </a>
            <a css={[colLg2, osLink]} href={linux} target="_blank">
              <IconLinux
                css={{
                  width: 35,
                  height: 40,
                  marginBottom: 8,
                }}
              />
              <div>Linux</div>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DownloadsSection

/* Copyright 2013 - 2024 Waiterio LLC */
import React, { useEffect } from 'react'
import getCookie from '@monorepo/shared/getCookie.js'

const CookiesListener = () => {
  function identifyGlobo() {
    if (typeof window !== 'undefined') {
      const globoUserId = getCookie('globoUserId')
      const globoSignature = getCookie('globoSignature')

      if (globoUserId) {
        window.$globo = window.$globo || []
        window.$globo.push(['setUserId', globoUserId, globoSignature])
      }
    }
  }

  useEffect(() => {
    function onChangeCookie(event) {
      if (
        event?.type === 'change' &&
        event?.changed?.some(
          cookie =>
            cookie.name === 'globoUserId' || cookie.name === 'globoSignature',
        )
      ) {
        identifyGlobo()
      }
    }

    if (typeof window !== 'undefined' && window.cookieStore) {
      window.cookieStore.addEventListener('change', onChangeCookie)
      return () => {
        window.removeEventListener('change', onChangeCookie)
      }
    }
  }, [])

  useEffect(() => {
    identifyGlobo()
  }, [])
}

export default CookiesListener

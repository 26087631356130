/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
import { useSearchParams } from 'react-router-dom'
import setCookie from '@monorepo/shared/setCookie.js'

const UtmTermCookie = () => {
  const [searchParams] = useSearchParams()
  const utm_term = searchParams.get('utm_term')

  if (utm_term) {
    setCookie('utm_term', utm_term)
  }
}

export default UtmTermCookie
